// axiosInstance.js
import axios from 'axios';
import qs from 'qs'; // Import the qs library

// Function to get the CSRF token from cookies
const getCsrfToken = () => {
    const name = 'csrftoken';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return decodeURIComponent(cookie.substring(name.length + 1));
        }
    }
    return null;
};

const csrfToken = getCsrfToken();

// Create an Axios instance with the CSRF token set in the headers
const axiosInstance = axios.create({
    headers: {
        'X-CSRFToken': csrfToken,
    },
    // Add the paramsSerializer to control how query parameters are serialized
    paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    },
});

export default axiosInstance;
