// src/components/FAQ.js

import React from 'react';
import './FAQ.css'; // Ensure your CSS file has the updated styles

const FAQ = () => {
    return (
        <div className="faq-container">
            <h1>Frequently Asked Questions</h1>
            <div className="faq-item">
                <h2>What is Zeus Analytics?</h2>
                <p>Zeus Analytics is a website designed to assist with making educated sports bets. We partner with different cappers in the space to know what information they use to make their picks and we aggregate that data for everyone in one concise location.</p>
            </div>
            <div className="faq-item">
                <h2>I subscribed to Zeus Analytics. What do I get?</h2>
                <p>Once you subscribe, you get access to all our tools and predictions, updated daily. We also send out our predictions to our subscribers via DubClub. We are tirelessly working to add more tools every week.</p>
            </div>
            <div className="faq-item">
                <h2>I subscribed to Zeus_Analytics but it is still redirecting me to the checkout page?</h2>
                <p>There are two reasons why this might happen. If you are a new subscriber, we are partnered with DubClub, who is partnered with Stripe to keep all your banking/credit information secure. When subscribing, there is sometimes a transmission delay up to an hour until it registers you into our system.  If you aren't new, we reset auth every morning to ensure security so if you leave your browser open, it might redirect you the next day. Long story short, try logging out and back in.</p>
            </div>
            <div className="faq-item">
                <h2>How often is the data updated?</h2>
                <p>As of Oct 27th, 2024 (the last time this FAQ was updated), we have three sports available. MLB and NBA data is updated automatically every day and the NFL data is updated week by week.</p>
            </div>
            <div className="faq-item">
                <h2>How often is the hit rate sheet updated?</h2>
                <p>NFL Props are not updated as often as some other props and there are some days where there are no props due to the weekly nature of football. Because of this reason, along with sportsbook data being very expensive, we can only update the page once a day. As subscribers begin to build for ZeusAnalytics, more resources will be able to be used to provide you with more up-to-date data.</p>
            </div>
            <div className="faq-item">
                <h2>Why can't I see the updates?</h2>
                <p>We work very hard to bring you new content weekly. Sometimes when updating the website, your browser will remember old data. To reload the page with new data, type Ctrl + Shift + R on Windows and CMD + Shift + R on Mac. This can also be solved by using an Incognito tab.</p>
            </div>
        </div>
    );
};

export default FAQ;
