import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CoverageMatrix.css';
import { Pie, Bar } from 'react-chartjs-2';  // Import Pie and Bar charts from react-chartjs-2
import 'chart.js/auto';  // Import for pie and bar charts
import ChartDataLabels from 'chartjs-plugin-datalabels';  // Import data labels plugin for pie charts

const teamColorDict = {
  "Arizona Cardinals": "#97233F",
  "Atlanta Falcons": "#A71930",
  "Baltimore Ravens": "#241773",
  "Buffalo Bills": "#00338D",
  "Carolina Panthers": "#0085CA",
  "Chicago Bears": "#0B162A",
  "Cincinnati Bengals": "#FB4F14",
  "Cleveland Browns": "#311D00",
  "Dallas Cowboys": "#041E42",
  "Denver Broncos": "#FB4F14",
  "Detroit Lions": "#0076B6",
  "Green Bay Packers": "#203731",
  "Houston Texans": "#03202F",
  "Indianapolis Colts": "#002C5F",
  "Jacksonville Jaguars": "#006778",
  "Kansas City Chiefs": "#E31837",
  "Las Vegas Raiders": "#A5ACAF",
  "Los Angeles Chargers": "#0073CF",
  "Los Angeles Rams": "#003594",
  "Miami Dolphins": "#008E97",
  "Minnesota Vikings": "#4F2683",
  "New England Patriots": "#002244",
  "New Orleans Saints": "#D3BC8D",
  "New York Giants": "#0B2265",
  "New York Jets": "#125740",
  "Philadelphia Eagles": "#004C54",
  "Pittsburgh Steelers": "#FFB612",
  "San Francisco 49ers": "#AA0000",
  "Seattle Seahawks": "#002244",
  "Tampa Bay Buccaneers": "#D50A0A",
  "Tennessee Titans": "#4B92DB",
  "Washington Commanders": "#5A1414"
};

const nflTeamAbbreviations = {
    "Arizona Cardinals": "ARZ",
    "Atlanta Falcons": "ATL",
    "Baltimore Ravens": "BLT",
    "Buffalo Bills": "BUF",
    "Carolina Panthers": "CAR",
    "Chicago Bears": "CHI",
    "Cincinnati Bengals": "CIN",
    "Cleveland Browns": "CLV",
    "Dallas Cowboys": "DAL",
    "Denver Broncos": "DEN",
    "Detroit Lions": "DET",
    "Green Bay Packers": "GB",
    "Houston Texans": "HST",
    "Indianapolis Colts": "IND",
    "Jacksonville Jaguars": "JAX",
    "Kansas City Chiefs": "KC",
    "Las Vegas Raiders": "LV",
    "Los Angeles Chargers": "LAC",
    "Los Angeles Rams": "LA",
    "Miami Dolphins": "MIA",
    "Minnesota Vikings": "MIN",
    "New England Patriots": "NE",
    "New Orleans Saints": "NO",
    "New York Giants": "NYG",
    "New York Jets": "NYJ",
    "Philadelphia Eagles": "PHI",
    "Pittsburgh Steelers": "PIT",
    "San Francisco 49ers": "SF",
    "Seattle Seahawks": "SEA",
    "Tampa Bay Buccaneers": "TB",
    "Tennessee Titans": "TEN",
    "Washington Commanders": "WAS"
  };

  const coverageTypes = [
    { label: 'All', value: 'all' },
    { label: 'Cover 0', value: '0' },
    { label: 'Cover 1', value: '1' },
    { label: 'Cover 2', value: '2' },
    { label: 'Cover 2 Man', value: 'man' },
    { label: 'Cover 3', value: '3' },
    { label: 'Cover 4', value: '4' },
    { label: 'Cover 6', value: '6' }
  ];
  
  // The data categories available for selection
  const dataCategories = [
    { label: 'Games Played', value: 'games' },
    { label: 'Routes Run', value: 'rte' },
    { label: 'Targets', value: 'tgt' },
    { label: 'Target Share Percentage', value: 'tgt_percentage' }, // DEFAULT
    { label: 'Receptions', value: 'rec' },
    { label: 'Yards', value: 'yds' },
    { label: 'Receiving Yards/Gm', value: 'recyds_g' },
    { label: 'YAC/REC', value: 'yac_rec' },
    { label: 'Yards Per Route Run', value: 'yprr' },
    { label: 'Receiving Touchdowns', value: 'td' }  // Updated Touchdowns label
  ];
  
  const CoverageMatrix = () => {
    const [defensiveData, setDefensiveData] = useState([]);
    const [offensiveData, setOffensiveData] = useState([]);
    const [selectedDefensiveTeam, setSelectedDefensiveTeam] = useState('');
    const [selectedOffensiveTeam, setSelectedOffensiveTeam] = useState('');
    const [selectedDefensiveYear, setSelectedDefensiveYear] = useState(2024);  // Default year is now 2024
    const [selectedOffensiveYear, setSelectedOffensiveYear] = useState(2024);  // Default year is now 2024
    const [selectedCoverageType, setSelectedCoverageType] = useState('all');
    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [selectedDataCategory, setSelectedDataCategory] = useState('tgt_percentage');  // Default data category
  
    // Function to check subscription status
    const checkSubscriptionStatus = async (token) => {
      try {
        const response = await axios.get('/api/check-subscription/', { params: { token } });
        const subscriptions = response.data;
        const currentTime = new Date().toISOString();
  
        const isSubscribed = subscriptions.some(subscription =>
          subscription.price.product.capper.id === 320906 && subscription.paid_until > currentTime
        );
        return isSubscribed;
      } catch (error) {
        console.error('Error checking subscription status:', error);
        return false;
      }
    };
  
    // Fetch team data and subscription status
    useEffect(() => {
      axios.get('https://zeusanalytics.org/api/coverage-matrix')
        .then(response => {
          const uniqueTeams = [...new Set(response.data.results.map(item => item.name))].sort();
          setTeams(uniqueTeams);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching team data:', error);
          setLoading(false);
        });
  
      axios.get('/profile/')
        .then(response => {
          const { access_token } = response.data;
          checkSubscriptionStatus(access_token).then(isSubscribed => {
            setIsAuthenticated(isSubscribed);
            if (!isSubscribed) {
              window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
            }
          });
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
          window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
        });
    }, []);
  
    // Fetch defensive and offensive data when the user selects teams and options
    useEffect(() => {
      if (selectedDefensiveTeam) fetchDefensiveData();
      if (selectedOffensiveTeam) fetchOffensiveData();
    }, [selectedDefensiveTeam, selectedOffensiveTeam, selectedDefensiveYear, selectedOffensiveYear, selectedCoverageType]);
  
    const fetchDefensiveData = () => {
      setLoading(true);
      axios.get('https://zeusanalytics.org/api/coverage-matrix', {
        params: { team: selectedDefensiveTeam, year: selectedDefensiveYear }
      })
      .then(response => {
        setDefensiveData(response.data.results);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching defensive data:', error);
        setLoading(false);
      });
    };
  
    const fetchOffensiveData = () => {
      const teamAbbreviation = nflTeamAbbreviations[selectedOffensiveTeam].replace(/\s+/g, '');
      setLoading(true);
  
      axios.get('https://zeusanalytics.org/api/target-share', {
        params: { team: teamAbbreviation, season: selectedOffensiveYear, coverage_type: selectedCoverageType }
      })
      .then(response => {
        let cleanedData = response.data.results.map(player => ({
          ...player,
          team: player.team.trim(),
          name: player.name.trim()
        }));
  
        if (selectedCoverageType === 'all') {
          cleanedData = cleanedData.filter(player => player.coverage_type === 'all');
        }
  
        setOffensiveData(cleanedData);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching offensive data:', error);
        setLoading(false);
      });
    };
  
    const getOffensiveBarChartData = (teamData) => {
      const labels = teamData.map(player => player.name);
      const dataValues = teamData.map(player => parseFloat(player[selectedDataCategory]));
  
      return {
        labels,
        datasets: [{
          label: dataCategories.find(category => category.value === selectedDataCategory).label,
          data: dataValues,
          backgroundColor: '#36A2EB',
        }]
      };
    };
  
    const getDefensiveManZonePieData = (teamData) => {
      const manPercentage = parseFloat(teamData.man_percentage);
      const zonePercentage = parseFloat(teamData.zone_percentage);
      const otherPercentage = 100 - manPercentage - zonePercentage;
  
      return {
        labels: ['Man %', 'Zone %', 'Other'],
        datasets: [{
          data: [manPercentage, zonePercentage, otherPercentage],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }]
      };
    };
  
    const getDefensiveCoveragePieData = (teamData) => {
        const percentages = [
          parseFloat(teamData.cover_0_percentage),
          parseFloat(teamData.cover_1_percentage),
          parseFloat(teamData.cover_2_percentage),
          parseFloat(teamData.cover_2_man_percentage),
          parseFloat(teamData.cover_3_percentage),
          parseFloat(teamData.cover_4_percentage),
          parseFloat(teamData.cover_6_percentage)
        ];
        const total = percentages.reduce((sum, val) => sum + val, 0);
        const otherPercentage = 100 - total;
    
        return {
          labels: ['Cover 0 %', 'Cover 1 %', 'Cover 2 %', 'Cover 2 Man %', 'Cover 3 %', 'Cover 4 %', 'Cover 6 %', 'Other'],
          datasets: [{
            data: [...percentages, otherPercentage],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#8A2BE2', '#20B2AA', '#FF4500', '#7FFF00', '#FFD700']
          }]
        };
      };
    
      const handleDefensiveTeamChange = (team) => {
        setSelectedDefensiveTeam(team);
      };
    
      const handleOffensiveTeamChange = (team) => {
        setSelectedOffensiveTeam(team);
      };
    
      const handleDefensiveYearChange = (e) => {
        setSelectedDefensiveYear(parseInt(e.target.value, 10));
      };
    
      const handleOffensiveYearChange = (e) => {
        setSelectedOffensiveYear(parseInt(e.target.value, 10));
      };
    
      const handleCoverageTypeChange = (e) => {
        setSelectedCoverageType(e.target.value);
      };
    
      const handleDataCategoryChange = (e) => {
        setSelectedDataCategory(e.target.value);
      };
    
      const getXAxisLabel = () => {
        return selectedDataCategory === 'tgt_percentage' ? '%' : '';  // Use % for Target Share Percentage, else nothing
      };
    
      if (loading || isAuthenticated === null) {
        return <div>Loading...</div>;
      }
    
      const offensiveTeamData = offensiveData.length > 0 ? offensiveData : [];
      const defensiveTeamData = defensiveData.length > 0 ? defensiveData[0] : null;
    
      return (
        <div className="coverage-matrix">
          <div className="sidebar left">
            <h2>Select Offensive Team</h2>
            <ul>
              {teams.map(team => (
                <li
                  key={team}
                  className={team === selectedOffensiveTeam ? 'active' : ''}
                  onClick={() => handleOffensiveTeamChange(team)}
                  style={{
                    color: team === selectedOffensiveTeam ? 'white' : '',
                    backgroundColor: team === selectedOffensiveTeam ? teamColorDict[team] : ''
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = teamColorDict[team]}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = team === selectedOffensiveTeam ? teamColorDict[team] : ''}
                >
                  {team}
                </li>
              ))}
            </ul>
          </div>
    
          <div className="content">
            {selectedOffensiveTeam && (
              <>
                <h2>{`${selectedOffensiveTeam} Offensive Stats`}</h2>
                <div className="filters">
                  <div className="year-filter">
                    <label htmlFor="offensive-year">Select Year: </label>
                    <select id="offensive-year" value={selectedOffensiveYear} onChange={handleOffensiveYearChange}>
                      {[2021, 2022, 2023, 2024].map(year => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <div className="coverage-filter">
                    <label htmlFor="coverage-type">Select Coverage Type: </label>
                    <select id="coverage-type" value={selectedCoverageType} onChange={handleCoverageTypeChange}>
                      {coverageTypes.map(type => (
                        <option key={type.value} value={type.value}>{type.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="data-category-filter">
                    <label htmlFor="data-category">Select Data Category: </label>
                    <select id="data-category" value={selectedDataCategory} onChange={handleDataCategoryChange}>
                      {dataCategories.map(category => (
                        <option key={category.value} value={category.value}>{category.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
    
                {offensiveTeamData.length > 0 && (
                  <div className="bar-chart">
                    <h3>{dataCategories.find(category => category.value === selectedDataCategory).label}</h3>
                    <Bar
                      data={getOffensiveBarChartData(offensiveTeamData)}
                      options={{
                        indexAxis: 'y',  // Horizontal bar chart
                        scales: {
                          x: {
                            beginAtZero: true,
                            ticks: {
                              callback: function(value) {
                                return value + getXAxisLabel();  // Use % for Target Share Percentage, else nothing
                              }
                            }
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
    
          <div className="sidebar right">
            <h2>Select Defensive Team</h2>
            <ul>
              {teams.map(team => (
                <li
                  key={team}
                  className={team === selectedDefensiveTeam ? 'active' : ''}
                  onClick={() => handleDefensiveTeamChange(team)}
                  style={{
                    color: team === selectedDefensiveTeam ? 'white' : '',
                    backgroundColor: team === selectedDefensiveTeam ? teamColorDict[team] : ''
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = teamColorDict[team]}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = team === selectedDefensiveTeam ? teamColorDict[team] : ''}
                >
                  {team}
                </li>
              ))}
            </ul>
          </div>
    
          {selectedDefensiveTeam && defensiveTeamData && (
            <div className="content">
              <h2>{`${selectedDefensiveTeam} Defensive Stats`}</h2>
              <div className="year-filter">
                <label htmlFor="defensive-year">Select Year: </label>
                <select id="defensive-year" value={selectedDefensiveYear} onChange={handleDefensiveYearChange}>
                  {[2021, 2022, 2023, 2024].map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
    
              <div className="pie-chart">
                <h3>Man vs Zone Percentage</h3>
                <Pie
                  data={getDefensiveManZonePieData(defensiveTeamData)}
                  options={{
                    plugins: {
                      datalabels: {
                        display: true,
                        formatter: (value) => `${value.toFixed(1)}%`,
                        color: '#fff',
                        font: {
                          weight: 'bold',
                          size: 16
                        }
                      }
                    }
                  }}
                  plugins={[ChartDataLabels]}
                />
              </div>
    
              <div className="pie-chart">
                <h3>Coverage Distribution</h3>
                <Pie
                  data={getDefensiveCoveragePieData(defensiveTeamData)}
                  options={{
                    plugins: {
                      datalabels: {
                        display: true,
                        formatter: (value) => `${value.toFixed(1)}%`,
                        color: '#fff',
                        font: {
                          weight: 'bold',
                          size: 16
                        }
                      }
                    }
                  }}
                  plugins={[ChartDataLabels]}
                />
              </div>
            </div>
          )}
        </div>
      );
    };
    
    export default CoverageMatrix;