import React from 'react';

const Login = () => {
  const handleLogin = () => {
    window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
  };

  return (
    <div>
      <h2>Login</h2>
      <button onClick={handleLogin}>
        Login with DubClub
      </button>
    </div>
  );
};

export default Login;