import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TopHRPredictions.css';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const teamNameToLinkDict = {
    "ARI": "https://www.mlbstatic.com/team-logos/109.svg",
    "ATL": "https://www.mlbstatic.com/team-logos/144.svg",
    "BOS": "https://www.mlbstatic.com/team-logos/111.svg",
    "BAL": "https://www.mlbstatic.com/team-logos/110.svg",
    "CHC": "https://www.mlbstatic.com/team-logos/112.svg",
    "CIN": "https://www.mlbstatic.com/team-logos/113.svg",
    "CLE": "https://www.mlbstatic.com/team-logos/114.svg",
    "COL": "https://www.mlbstatic.com/team-logos/115.svg",
    "CWS": "https://www.mlbstatic.com/team-logos/145.svg",
    "DET": "https://www.mlbstatic.com/team-logos/116.svg",
    "HOU": "https://www.mlbstatic.com/team-logos/117.svg",
    "KC": "https://www.mlbstatic.com/team-logos/118.svg",
    "LAA": "https://www.mlbstatic.com/team-logos/108.svg",
    "LAD": "https://www.mlbstatic.com/team-logos/119.svg",
    "MIA": "https://www.mlbstatic.com/team-logos/146.svg",
    "MIL": "https://www.mlbstatic.com/team-logos/158.svg",
    "MIN": "https://www.mlbstatic.com/team-logos/142.svg",
    "NYM": "https://www.mlbstatic.com/team-logos/121.svg",
    "NYY": "https://www.mlbstatic.com/team-logos/147.svg",
    "OAK": "https://www.mlbstatic.com/team-logos/133.svg",
    "PHI": "https://www.mlbstatic.com/team-logos/143.svg",
    "PIT": "https://www.mlbstatic.com/team-logos/134.svg",
    "SD": "https://www.mlbstatic.com/team-logos/135.svg",
    "SEA": "https://www.mlbstatic.com/team-logos/136.svg",
    "SF": "https://www.mlbstatic.com/team-logos/137.svg",
    "STL": "https://www.mlbstatic.com/team-logos/138.svg",
    "TB": "https://www.mlbstatic.com/team-logos/139.svg",
    "TEX": "https://www.mlbstatic.com/team-logos/140.svg",
    "TOR": "https://www.mlbstatic.com/team-logos/141.svg",
    "WAS": "https://www.mlbstatic.com/team-logos/120.svg"
};

const formatOdds = (odds) => {
    return odds > 0 ? `+${odds}` : odds;
};

const TopHRPredictions = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const minDate = new Date(Date.UTC(2024, 7, 17, 0, 0, 0)); // Ensure the date is 2024-08-17 at midnight UTC
    const maxDate = new Date();

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchPredictions = async (date) => {
        setLoading(true);
        try {
            const response = await axios.get('https://zeusanalytics.org/api/top-hr-predictions-history/', {
                params: { date: date.toISOString().split('T')[0] }
            });
            if (response.data && response.data.results) {
                setData(response.data.results);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        fetchPredictions(date);
        setIsDatePickerOpen(false); // Close date picker after date selection
    };

    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the date picker visibility
    };

    useEffect(() => {
        const getEasternTime = () => {
            const options = {
                timeZone: 'America/New_York',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            };
            const formatter = new Intl.DateTimeFormat([], options);
            const parts = formatter.formatToParts(new Date());

            const month = parts.find(p => p.type === 'month').value;
            const day = parts.find(p => p.type === 'day').value;
            const year = parts.find(p => p.type === 'year').value;

            return new Date(year, month - 1, day);
        };

        const easternTimeDate = getEasternTime();
        setSelectedDate(easternTimeDate);

        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);
                    if (isSubscribed) {
                        fetchPredictions(easternTimeDate);
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className="top-hr-predictions">
            <h1>The MLB Script</h1>
            <div className="date-picker-container">
                {!isDatePickerOpen && (
                    <FaCalendarAlt className="calendar-icon" onClick={toggleDatePicker} />
                )}
                {isDatePickerOpen && (
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        maxDate={maxDate}
                        minDate={minDate}
                        dateFormat="yyyy-MM-dd"
                        inline // Display the date picker inline
                        className="date-picker"
                    />
                )}
            </div>
            <h2 style={{ fontSize: '0.8em', marginTop: '-10px', color: '#555' }}>{selectedDate ? selectedDate.toDateString() : ''}</h2>
            <div className="table-container">
            <table className="modern-table">
                    <thead>
                        <tr>
                            <th>Batter Name</th>
                            <th>Team</th>
                            <th>AI Boost</th>
                            <th>FanDuel</th>
                            <th>Caesars</th>
                            <th>BetMGM</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            const bestBook = row.best_book;
                            const getCellClass = (bookName) => {
                                return bestBook && bestBook.toLowerCase() === bookName.toLowerCase() ? 'highlight' : '';
                            };

                            return (
                                <tr key={index}>
                                    <td className="batter-cell">
                                        <div className="batter-label">Batter Name</div>
                                        <div className="batter-info">
                                            <img src={row.player_picture} alt={`${row.batter}`} className="player-picture" />
                                            <span>{row.batter}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="cell-label">Team</div>
                                        <img src={teamNameToLinkDict[row.batter_team]} alt={row.batter_team} className="team-logo-ev" />
                                        {row.batter_team}
                                    </td>
                                    <td className="gradient-text">
                                        <div className="cell-label">AI Boost</div>
                                        {(row.prediction_score * 10).toFixed(1)}
                                    </td>
                                    <td className={getCellClass('fanduel')}>
                                        <div className="cell-label">FanDuel</div>
                                        {row.fanduel ? formatOdds(row.fanduel) : '-'}
                                    </td>
                                    <td className={getCellClass('williamhill_us')}>
                                        <div className="cell-label">Caesars</div>
                                        {row.williamhill_us ? formatOdds(row.williamhill_us) : '-'}
                                    </td>
                                    <td className={getCellClass('betmgm')}>
                                        <div className="cell-label">BetMGM</div>
                                        {row.betmgm ? formatOdds(row.betmgm) : '-'}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <p className="disclaimer">
                Odds are provided by The Odds API and updated every 15 minutes. Please verify odds.
            </p>
        </div>
    );
};

export default TopHRPredictions;
