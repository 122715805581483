// src/components/AwardSection.js

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { teamAbbreviationToLogoUrl } from '../utils/teamAbbreviationToLogoUrl';
import { teamAbbreviationToColor } from '../utils/teamColors';
import { FaTrophy } from 'react-icons/fa'; // Import trophy icon

// Styled Components

// Updated Section with flexible height to prevent content from being cut off
const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  min-height: auto; /* Allow content to expand if needed */
  width: 100vw; /* Full screen width, avoid any side margins */
  background: ${props => props.bgColor || '#000000'}; /* Dynamic background color */
  position: relative;
  padding: 20px; /* Reduced padding to fit more content */
  box-sizing: border-box;

  /* Ensure no overflow and avoid the content getting cut off */
  

  /* For responsive layouts */
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const AwardTitle = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff; /* White text */

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const TrophyIcon = styled(FaTrophy)`
  color: gold;
  margin-left: 10px;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-7px);
    }
  }
`;

const PlayerImage = styled(motion.img)`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 15px solid ${props => props.borderColor || 'gold'};
  object-fit: cover;
  margin-bottom: 20px;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
  transition: transform 0.5s ease, box-shadow 0.5s ease; /* Slower transition */
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(255, 215, 0, 0.8);
  }
  
  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    border: 10px solid ${props => props.borderColor || 'gold'};
  }
  
  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
    border: 5px solid ${props => props.borderColor || 'gold'};
  }
`;

const TeamLogo = styled(motion.img)`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 20px;
  transition: transform 0.5s ease, box-shadow 0.5s ease; /* Slower transition */
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.6);
  }
  
  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
  
  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const AwardDetails = styled(motion.div)`
  font-size: 1.5rem;
  text-align: center;
  color: #dddddd; /* Light gray text */
  
  p {
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const CountBadge = styled.div`
  background-color: gold;
  color: black;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  margin-top: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  transition: transform 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
    font-size: 0.9rem;
  }
`;

const StatNumber = styled.span`
  font-size: 2.5rem;
  color: gold;
  font-weight: bold;
  margin-left: 5px;
  position: relative;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const CommentText = styled.p`
  font-size: 1.2rem;
  color: #ffffff;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

// Mapping award categories to comments
const awardCategoryToComment = {
  // Player Awards
  'Top Point Scorer': (count, name) => `${name} led the league in scoring! They have won this award ${count} time(s) this season.`,
  'Top Rebounds Player': (count, name) => `${name} dominated the boards last night! They have won this award ${count} time(s) this season.`,
  'Top Assists Player': (count, name) => `${name} set up their teammates brilliantly! They have won this award ${count} time(s) this season.`,
  'Most Three Pointers Made': (count, name) => `${name} was on fire from beyond the arc! They have won this award ${count} time(s) this season.`,
  'Top Steals Player': (count, name) => `${name} had the most active hands last night! They have won this award ${count} time(s) this season.`,
  'Top Blocks Player': (count, name) => `${name} was a defensive wall last night! They have won this award ${count} time(s) this season.`,
  'Top Minutes Played': (count, name) => `${name} was on the court the longest! They have won this award ${count} time(s) this season.`,
  'Most Threes Taken': (count, name) => `${name} took the most three-point attempts! They have won this award ${count} time(s) this season.`,
  'Most Free Throws Made': (count, name) => `${name} was knocking down the free throws! They have won this award ${count} time(s) this season.`,
  'Lowest Point Scorer (25+ mins)': (count, name) => `${name} was just out there doing cardio! 🏃‍♂️ They have won this award ${count} time(s) this season.`,
  'Most Turnovers': (count, name) => `${name} had the most turnovers last night! They have won this award ${count} time(s) this season.`,
  'Lowest FG PCT (min 5 shots)': (count, name) => `${name} was throwing up BRICKS! 🧱 They have won this award ${count} time(s) this season.`,
  'Lowest 3P PCT (min 5 shots)': (count, name) => `${name} was a three-point struggle last night! They have won this award ${count} time(s) this season.`,

  // Team Awards
  'Team Most Points': (count, name) => `${name} scored the most points! They have won this award ${count} time(s) this season.`,
  'Team Most Rebounds': (count, name) => `${name} dominated the rebounds! They have won this award ${count} time(s) this season.`,
  'Team Most Assists': (count, name) => `${name} set up their teammates brilliantly! They have won this award ${count} time(s) this season.`,
  'Team Most 3PM Made': (count, name) => `${name} was on fire from beyond the arc! They have won this award ${count} time(s) this season.`,
  'Team Most Steals': (count, name) => `${name} had the most active hands! They have won this award ${count} time(s) this season.`,
  'Team Most Blocks': (count, name) => `${name} was a defensive powerhouse! They have won this award ${count} time(s) this season.`,
  'Team Most 3PM Taken': (count, name) => `${name} took the most three-point attempts! They have won this award ${count} time(s) this season.`,
  'Team Most Free Throws Made': (count, name) => `${name} was knocking down the free throws! They have won this award ${count} time(s) this season.`,
  'Team Given Up Most Points': (count, name) => `${name} gave up the most points! They have won this award ${count} time(s) this season.`,
  'Team Given Up Most Rebounds': (count, name) => `${name} gave up the most rebounds! They have won this award ${count} time(s) this season.`,
  'Team Given Up Most Assists': (count, name) => `${name} gave up the most assists! They have won this award ${count} time(s) this season.`,
  'Team Most 3s Given Up': (count, name) => `${name} gave up the most three-point attempts! They have won this award ${count} time(s) this season.`,
  'Team Lowest FG PCT': (count, name) => `${name} struggled with shooting efficiency! They have won this award ${count} time(s) this season.`,
  'Team Lowest 3P PCT': (count, name) => `${name} struggled with three-point shooting! They have won this award ${count} time(s) this season.`,
  // Add more mappings as needed
};

// Mapping award categories to stat labels
const awardCategoryToStatLabel = {
  // Player Awards
  'Top Point Scorer': 'Points',
  'Top Rebounds Player': 'Rebounds',
  'Top Assists Player': 'Assists',
  'Most Three Pointers Made': '3PM',
  'Top Steals Player': 'Steals',
  'Top Blocks Player': 'Blocks',
  'Top Minutes Played': 'Minutes Played',
  'Most Threes Taken': '3PA',
  'Most Free Throws Made': 'FTM',
  'Lowest Point Scorer (25+ mins)': 'Points',
  'Most Turnovers': 'Turnovers',
  'Lowest FG PCT (min 5 shots)': 'FG%',
  'Lowest 3P PCT (min 5 shots)': '3P%',

  // Team Awards
  'Team Most Points': 'Points',
  'Team Most Rebounds': 'Rebounds',
  'Team Most Assists': 'Assists',
  'Team Most 3PM Made': '3PM',
  'Team Most Steals': 'Steals',
  'Team Most Blocks': 'Blocks',
  'Team Most 3PM Taken': '3PA',
  'Team Most Free Throws Made': 'FTM',
  'Team Given Up Most Points': 'Points Allowed',
  'Team Given Up Most Rebounds': 'Rebounds Allowed',
  'Team Given Up Most Assists': 'Assists Allowed',
  'Team Most 3s Given Up': '3PA Allowed',
  'Team Lowest FG PCT': 'FG%',
  'Team Lowest 3P PCT': '3P%',
  // Add other mappings as needed
};

const AwardSection = ({ award, type }) => {
  // Determine background color based on award type
  const bgColor = type === 'player' ? '#1c1c1c' : (award.team_color || '#2a2a2a'); // Darker shades or team color

  // Team abbreviation
  let teamAbbreviation = '';
  if (type === 'team') {
    teamAbbreviation = award.team_name; // Directly use abbreviation
  } else if (type === 'player') {
    teamAbbreviation = award.team; // Assuming award.team is abbreviation
  }

  // Team logo URL
  const teamLogoUrl = teamAbbreviation ? teamAbbreviationToLogoUrl[teamAbbreviation] : '';

  // Team color for border
  const teamColor = award.team_color || 'gold';

  // Get the stat label
  const statLabel = awardCategoryToStatLabel[award.award_category];

  // Get the stat value
  let statValue = null;
  if (type === 'player') {
    statValue = award.value;
    // Check if stat is percentage
    if (award.award_category.includes('PCT')) {
      statValue = parseFloat(statValue).toFixed(1);
    }
  } else if (type === 'team') {
    statValue = award.value;
    if (award.award_category.includes('PCT')) {
      statValue = parseFloat(statValue).toFixed(1);
    }
  }

  // Get the comment based on award_category and count
  const playerNameOrTeam = type === 'player' ? award.player_name : award.team_name;
  const comment = awardCategoryToComment[award.award_category]
    ? awardCategoryToComment[award.award_category](award.count, playerNameOrTeam)
    : '';

  return (
    <Section
      bgColor={bgColor}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <AwardTitle
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
      >
        {award.award_category}
        <TrophyIcon size={30} />
      </AwardTitle>
      
      {type === 'team' && teamLogoUrl && (
        <TeamLogo
          src={teamLogoUrl}
          alt={award.team_name}
          loading="lazy"
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
        />
      )}

      {type === 'player' && (
        <>
          <PlayerImage
            src={award.player_picture || 'https://via.placeholder.com/300'}
            alt={award.player_name}
            borderColor={teamColor} // Ensure border color matches team color
            loading="lazy" // Lazy loading
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          />
          {teamLogoUrl && (
            <TeamLogo
              src={teamLogoUrl}
              alt={award.team}
              loading="lazy" // Lazy loading
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
            />
          )}
        </>
      )}
      
      <AwardDetails
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        viewport={{ once: true }}
      >
        {type === 'player' ? (
          <>
            <p><strong>Name:</strong> {award.player_name}</p>
            <p><strong>Team:</strong> {teamAbbreviation ? teamAbbreviation : 'N/A'}</p>
            {statLabel && statValue !== null && (
              <p>
                <strong>{statLabel}:</strong> 
                <StatNumber>
                  {award.award_category.includes('PCT') ? `${statValue}%` : statValue}
                </StatNumber>
              </p>
            )}
            {comment && <CommentText>{comment}</CommentText>}
            {award.count > 1 && <CountBadge>{award.count} Awards</CountBadge>}
          </>
        ) : (
          <>
            <p><strong>Team:</strong> {award.team_name}</p>
            {statLabel && statValue !== null && (
              <p>
                <strong>{statLabel}:</strong> 
                <StatNumber>
                  {award.award_category.includes('PCT') ? `${statValue}%` : statValue}
                </StatNumber>
              </p>
            )}
            {comment && <CommentText>{comment}</CommentText>}
            {award.count > 1 && <CountBadge>{award.count} Awards</CountBadge>}
          </>
        )}
      </AwardDetails>
    </Section>
  );
};

AwardSection.propTypes = {
  award: PropTypes.shape({
    id: PropTypes.number.isRequired,
    award_category: PropTypes.string.isRequired,
    player_picture: PropTypes.string,
    team_logo: PropTypes.string,
    player_name: PropTypes.string,
    team: PropTypes.string, // Assuming abbreviation
    team_name: PropTypes.string, // Assuming abbreviation for team awards
    value: PropTypes.number, // Changed from PropTypes.float to PropTypes.number
    count: PropTypes.number.isRequired,
    team_color: PropTypes.string,
    // Add other fields as needed
  }).isRequired,
  type: PropTypes.oneOf(['player', 'team']).isRequired,
};

export default AwardSection;
