// src/components/Scores.js
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Scores.css';

const teamLogos = {
    "Arizona Diamondbacks": "https://www.mlbstatic.com/team-logos/109.svg",
    "Atlanta Braves": "https://www.mlbstatic.com/team-logos/144.svg",
    "Boston Red Sox": "https://www.mlbstatic.com/team-logos/111.svg",
    "Baltimore Orioles": "https://www.mlbstatic.com/team-logos/110.svg",
    "Chicago Cubs": "https://www.mlbstatic.com/team-logos/112.svg",
    "Cincinnati Reds": "https://www.mlbstatic.com/team-logos/113.svg",
    "Cleveland Guardians": "https://www.mlbstatic.com/team-logos/114.svg",
    "Colorado Rockies": "https://www.mlbstatic.com/team-logos/115.svg",
    "Chicago White Sox": "https://www.mlbstatic.com/team-logos/145.svg",
    "Detroit Tigers": "https://www.mlbstatic.com/team-logos/116.svg",
    "Houston Astros": "https://www.mlbstatic.com/team-logos/117.svg",
    "Kansas City Royals": "https://www.mlbstatic.com/team-logos/118.svg",
    "Los Angeles Angels": "https://www.mlbstatic.com/team-logos/108.svg",
    "Los Angeles Dodgers": "https://www.mlbstatic.com/team-logos/119.svg",
    "Miami Marlins": "https://www.mlbstatic.com/team-logos/146.svg",
    "Milwaukee Brewers": "https://www.mlbstatic.com/team-logos/158.svg",
    "Minnesota Twins": "https://www.mlbstatic.com/team-logos/142.svg",
    "New York Mets": "https://www.mlbstatic.com/team-logos/121.svg",
    "New York Yankees": "https://www.mlbstatic.com/team-logos/147.svg",
    "Oakland Athletics": "https://www.mlbstatic.com/team-logos/133.svg",
    "Philadelphia Phillies": "https://www.mlbstatic.com/team-logos/143.svg",
    "Pittsburgh Pirates": "https://www.mlbstatic.com/team-logos/134.svg",
    "San Diego Padres": "https://www.mlbstatic.com/team-logos/135.svg",
    "Seattle Mariners": "https://www.mlbstatic.com/team-logos/136.svg",
    "San Francisco Giants": "https://www.mlbstatic.com/team-logos/137.svg",
    "St. Louis Cardinals": "https://www.mlbstatic.com/team-logos/138.svg",
    "Tampa Bay Rays": "https://www.mlbstatic.com/team-logos/139.svg",
    "Texas Rangers": "https://www.mlbstatic.com/team-logos/140.svg",
    "Toronto Blue Jays": "https://www.mlbstatic.com/team-logos/141.svg",
    "Washington Nationals": "https://www.mlbstatic.com/team-logos/120.svg"
};

function Scores() {
    const [scores, setScores] = useState([]);
    const pacificNow = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
    const [selectedDate, setSelectedDate] = useState(new Date(pacificNow));
    const [showDatePicker, setShowDatePicker] = useState(false);

    const fetchScores = async (date) => {
        try {
            const formattedDate = date.toISOString().split('T')[0].replace(/-/g, '');
            const response = await fetch(`https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${formattedDate}`);
            const data = await response.json();
            setScores(data.events);
        } catch (error) {
            console.error("Error fetching scores:", error);
        }
    };

    useEffect(() => {
        fetchScores(selectedDate);
        const interval = setInterval(() => fetchScores(selectedDate), 5000); // Refresh every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setShowDatePicker(false);
    };

    const goToToday = () => {
        const today = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" });
        setSelectedDate(new Date(today));
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const formatTime = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
        return new Date(dateString).toLocaleTimeString([], options);
    };

    const formatInning = (inningHalf, inning) => {
        const suffix = (n) => {
            if (n === 1) return 'st';
            if (n === 2) return 'nd';
            if (n === 3) return 'rd';
            return 'th';
        };
        return `${inningHalf === 'top' ? 'Top' : 'Bottom'} ${inning}${suffix(inning)}`;
    };

    return (
        <div className="scores-container">
            <h2>MLB Scores</h2>
            <div className="calendar-container" onClick={() => setShowDatePicker(!showDatePicker)}>
                <span>{formatDate(selectedDate)}</span>
                <div className="indicator"></div>
                <button className="today-button" onClick={goToToday}>Today</button>
            </div>
            {showDatePicker && (
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    inline
                />
            )}
            {scores.length === 0 ? (
                <p>No games available.</p>
            ) : (
                scores.map((game, index) => {
                    const homeTeam = game.competitions[0].competitors[0];
                    const awayTeam = game.competitions[0].competitors[1];
                    const homeScore = homeTeam.score || 0;
                    const awayScore = awayTeam.score || 0;
                    const homeLogo = teamLogos[homeTeam.team.displayName];
                    const awayLogo = teamLogos[awayTeam.team.displayName];
                    const gameTime = game.status.type.state === 'pre' ? formatTime(game.date) : game.status.type.description;
                    const isFinal = game.status.type.state === 'post';
                    const inning = game.status.period || 0;
                    const inningHalf = game.status.type.shortDetail && game.status.type.shortDetail.split(' ')[0] ? game.status.type.shortDetail.split(' ')[0].toLowerCase() : 'pre';
                    const outs = game.competitions[0].situation ? game.competitions[0].situation.outs : 0;
                    const onFirst = game.competitions[0].situation ? game.competitions[0].situation.onFirst : false;
                    const onSecond = game.competitions[0].situation ? game.competitions[0].situation.onSecond : false;
                    const onThird = game.competitions[0].situation ? game.competitions[0].situation.onThird : false;
                    const lastPlay = game.competitions[0].situation ? game.competitions[0].situation.lastPlay : null;
                    const currentPitcher = game.competitions[0].situation ? game.competitions[0].situation.pitcher : null;
                    const currentBatter = game.competitions[0].situation ? game.competitions[0].situation.batter : null;
                    const venue = game.competitions[0].venue ? game.competitions[0].venue.fullName : null;
                    const temperature = game.weather ? game.weather.temperature : null;
                    const condition = game.weather ? game.weather.condition : null;

                    const bases = {
                        first: onFirst,
                        second: onSecond,
                        third: onThird
                    };

                    return (
                        <div className="game-container" key={index}>
                            <div className="team-container">
                                <img src={awayLogo} alt={awayTeam.team.displayName} className="team-logo" />
                                <span className="team-name">{awayTeam.team.displayName}</span>
                                <span className="team-score">{awayScore}</span>
                            </div>
                            <div className="vs">vs</div>
                            <div className="team-container">
                                <img src={homeLogo} alt={homeTeam.team.displayName} className="team-logo" />
                                <span className="team-name">{homeTeam.team.displayName}</span>
                                <span className="team-score">{homeScore}</span>
                            </div>
                            {game.status.type.state === 'pre' ? (
                                <div className="game-time">{gameTime}</div>
                            ) : isFinal ? (
                                <div className="game-time">Final</div>
                            ) : (
                                <div className="game-info">
                                    <div className="pitcher-batter-info">
                                        {currentPitcher && (
                                            <div className="player-info">
                                                <img src={currentPitcher.athlete.headshot} alt={currentPitcher.athlete.fullName} className="player-photo" />
                                                <span>P: {currentPitcher.athlete.fullName}</span>
                                            </div>
                                        )}
                                        {currentBatter && (
                                            <div className="player-info">
                                                <img src={currentBatter.athlete.headshot} alt={currentBatter.athlete.fullName} className="player-photo" />
                                                <span>B: {currentBatter.athlete.fullName}</span>
                                            </div>
                                        )}
                                        <div className="venue-info">
                                            {venue && <div>Stadium: {venue}</div>}
                                            {temperature && <div>Temperature: {temperature}°F, {condition}</div>}
                                        </div>
                                    </div>
                                    <div className="game-details">
                                        <div className="inning-status">{formatInning(inningHalf, inning)}</div>
                                        <div>{outs} Outs</div>
                                        <div className="bases-container">
                                            <div className="bases">
                                                <div className={`base base-third ${bases.third ? 'occupied' : ''}`} />
                                                <div className={`base base-second ${bases.second ? 'occupied' : ''}`} />
                                                <div className={`base base-first ${bases.first ? 'occupied' : ''}`} />
                                            </div>
                                        </div>
                                        {lastPlay && <div className="last-play"><strong>Last Play:</strong> {lastPlay.text}</div>}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })
            )}
        </div>
    );
}

export default Scores;
