import React, { useState } from 'react';
import './OddsConverter.css';

const OddsConverter = () => {
    const [fraction, setFraction] = useState('');
    const [decimal, setDecimal] = useState('');
    const [american, setAmerican] = useState('');
    const [implied, setImplied] = useState('');

    const handleFractionChange = (e) => {
        const value = e.target.value;
        setFraction(value);

        if (value) {
            const [numerator, denominator] = value.split('/');
            const dec = (parseFloat(numerator) / parseFloat(denominator)) + 1;
            const amer = dec >= 2 ? (dec - 1) * 100 : -100 / (dec - 1);
            const impl = 1 / dec * 100;
            setDecimal(dec.toFixed(2));
            setAmerican(amer.toFixed(0));
            setImplied(impl.toFixed(2) + '%');
        } else {
            setDecimal('');
            setAmerican('');
            setImplied('');
        }
    };

    const handleDecimalChange = (e) => {
        const value = parseFloat(e.target.value);
        setDecimal(e.target.value);

        if (!isNaN(value)) {
            const frac = value >= 2 ? `${(value - 1).toFixed(2)}/1` : `1/${(1 / (value - 1)).toFixed(2)}`;
            const amer = value >= 2 ? (value - 1) * 100 : -100 / (value - 1);
            const impl = 1 / value * 100;
            setFraction(frac);
            setAmerican(amer.toFixed(0));
            setImplied(impl.toFixed(2) + '%');
        } else {
            setFraction('');
            setAmerican('');
            setImplied('');
        }
    };

    const handleAmericanChange = (e) => {
        const value = parseFloat(e.target.value);
        setAmerican(e.target.value);

        if (!isNaN(value)) {
            const dec = value >= 0 ? (value / 100) + 1 : (100 / -value) + 1;
            const frac = dec >= 2 ? `${(dec - 1).toFixed(2)}/1` : `1/${(1 / (dec - 1)).toFixed(2)}`;
            const impl = 1 / dec * 100;
            setDecimal(dec.toFixed(2));
            setFraction(frac);
            setImplied(impl.toFixed(2) + '%');
        } else {
            setFraction('');
            setDecimal('');
            setImplied('');
        }
    };

    const handleImpliedChange = (e) => {
        const value = parseFloat(e.target.value);
        setImplied(e.target.value);

        if (!isNaN(value) && value > 0 && value < 100) {
            const dec = 100 / value;
            const frac = dec >= 2 ? `${(dec - 1).toFixed(2)}/1` : `1/${(1 / (dec - 1)).toFixed(2)}`;
            const amer = dec >= 2 ? (dec - 1) * 100 : -100 / (dec - 1);
            setDecimal(dec.toFixed(2));
            setFraction(frac);
            setAmerican(amer.toFixed(0));
        } else {
            setFraction('');
            setDecimal('');
            setAmerican('');
        }
    };

    return (
        <div className="converter-container">
            <h1>Odds Converter</h1>
            <div className="form-container">
                <div className="table-container-oc">
                    <table>
                        <thead>
                            <tr>
                                <th>Fraction</th>
                                <th>Decimal</th>
                                <th>American (Moneyline)</th>
                                <th>Implied Probability</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="text" value={fraction} onChange={handleFractionChange} /></td>
                                <td><input type="text" value={decimal} onChange={handleDecimalChange} /></td>
                                <td><input type="text" value={american} onChange={handleAmericanChange} /></td>
                                <td><input type="text" value={implied} onChange={handleImpliedChange} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default OddsConverter;
