import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DefensiveNFLStats.css';

const positions = {
    QB: 'defenses-against-qbs',
    RB: 'defenses-against-rbs',
    WR: 'defenses-against-wrs',
    TE: 'defenses-against-tes'
};

const years = [2024, 2023, 2022, 2021, 2020];

const teamDefenseLogos = {
    "Arizona Cardinals Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/ARI",
    "Atlanta Falcons Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/ATL",
    "Baltimore Ravens Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/BAL",
    "Buffalo Bills Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/BUF",
    "Carolina Panthers Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/CAR",
    "Chicago Bears Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/CHI",
    "Cincinnati Bengals Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/CIN",
    "Cleveland Browns Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/CLE",
    "Dallas Cowboys Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/DAL",
    "Denver Broncos Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/DEN",
    "Detroit Lions Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/DET",
    "Green Bay Packers Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/GB",
    "Houston Texans Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/HOU",
    "Indianapolis Colts Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/IND",
    "Jacksonville Jaguars Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/JAX",
    "Kansas City Chiefs Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/KC",
    "Las Vegas Raiders Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/LV",
    "Los Angeles Chargers Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/LAC",
    "Los Angeles Rams Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/LA",
    "Miami Dolphins Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/MIA",
    "Minnesota Vikings Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/MIN",
    "New England Patriots Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/NE",
    "New Orleans Saints Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/NO",
    "New York Giants Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/NYG",
    "New York Jets Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/NYJ",
    "Philadelphia Eagles Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/PHI",
    "Pittsburgh Steelers Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/PIT",
    "San Francisco 49ers Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/SF",
    "Seattle Seahawks Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/SEA",
    "Tampa Bay Buccaneers Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/TB",
    "Tennessee Titans Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/TEN",
    "Washington Commanders Defense": "https://static.www.nfl.com/t_person_squared_mobile/f_auto/league/api/clubs/logos/WAS",
};

const columnsToHide = {
    QB: ['id', 'fantasy_points_against_avg', 'fantasy_points_against_rk', 'rz_touches', 'rz_g2g'],
    RB: ['id', 'fantasy_points_against_avg', 'fantasy_points_against_rank', 'rz_touches', 'rz_g2g', 'rz_targets'],
    WR: ['id', 'fantasy_points_against_avg', 'fantasy_points_against_rank', 'rz_touches', 'rz_g2g', 'rz_targets'],
    TE: ['id', 'fantasy_points_against_avg', 'fantasy_points_against_rank', 'rz_touches', 'rz_g2g', 'rz_targets'],
};

const columnHeaders = {
    team: 'Team',
    passing_completions: 'Passing Completions',
    passing_attempts: 'Passing Attempts',
    passing_yards: 'Passing Yards Allowed',
    passing_tds: 'Passing TDs Allowed',
    passing_ints: 'Passing INTs', // Capitalizing "ints"
    rushing_attempts: 'Rushing Attempts',
    rushing_yards: 'Rushing Yards',
    rushing_tds: 'Rushing TDs Allowed',
    receiving_receptions: 'Receptions',
    receiving_targets: 'Targets',
    receiving_yards: 'Receiving Yards',
    receiving_tds: 'Receiving TDs'
};

const DefensiveStats = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [selectedYear, setSelectedYear] = useState(2024);
    const [selectedPosition, setSelectedPosition] = useState('RB');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    useEffect(() => {
        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);
                    if (isSubscribed) {
                        fetchData(selectedYear, selectedPosition);
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, [selectedYear, selectedPosition]);

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = async (year, position) => {
        setLoading(true);
        try {
            const response = await axios.get(`/api/${positions[position]}-${year}/`);
            if (response.data && response.data.results) {
                setData(response.data.results);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value));
    };

    const handlePositionChange = (event) => {
        setSelectedPosition(event.target.value);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortedData = () => {
        if (sortConfig.key) {
            return [...data].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    };

    const renderTableHeaders = () => {
        return (
            <tr>
                {Object.keys(data[0] || {}).map((key) => {
                    if (!columnsToHide[selectedPosition].includes(key)) {
                        return (
                            <th key={key} onClick={() => handleSort(key)}>
                                {columnHeaders[key] || key.replace(/_/g, ' ')}
                                {sortConfig.key === key && (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
                            </th>
                        );
                    }
                    return null;
                })}
            </tr>
        );
    };

    const renderTableRows = () => {
        return getSortedData().map((row, index) => (
            <tr key={index}>
                {Object.keys(row).map((key) => {
                    if (!columnsToHide[selectedPosition].includes(key)) {
                        return (
                            <td key={key}>
                                {key === 'team' ? (
                                    <>
                                        <img src={teamDefenseLogos[row[key]]} alt={row[key]} style={{ width: '20px', marginRight: '10px' }} />
                                        {row[key]}
                                    </>
                                ) : (
                                    row[key]
                                )}
                            </td>
                        );
                    }
                    return null;
                })}
            </tr>
        ));
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (isAuthenticated === null) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="defensive-nfl-stats">
            <h1>Defensive Stats Against Positions</h1>
            <div className="filters">
                <select onChange={handleYearChange} value={selectedYear}>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                <select onChange={handlePositionChange} value={selectedPosition}>
                    {Object.keys(positions).map((position) => (
                        <option key={position} value={position}>
                            {position}
                        </option>
                    ))}
                </select>
            </div>
            <div className="table-container">
                <table className="modern-table">
                    <thead>{renderTableHeaders()}</thead>
                    <tbody>{renderTableRows()}</tbody>
                </table>
            </div>
        </div>
    );
};

export default DefensiveStats;
