import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'nouislider/dist/nouislider.css';
import './ExitVelocityTracker.css'; // You can create a new CSS file for StartingPitcherStats or reuse this one

const teamNameToLinkDict = {
    "ARI": "https://www.mlbstatic.com/team-logos/109.svg",
    "ATL": "https://www.mlbstatic.com/team-logos/144.svg",
    "BOS": "https://www.mlbstatic.com/team-logos/111.svg",
    "BAL": "https://www.mlbstatic.com/team-logos/110.svg",
    "CHC": "https://www.mlbstatic.com/team-logos/112.svg",
    "CIN": "https://www.mlbstatic.com/team-logos/113.svg",
    "CLE": "https://www.mlbstatic.com/team-logos/114.svg",
    "COL": "https://www.mlbstatic.com/team-logos/115.svg",
    "CWS": "https://www.mlbstatic.com/team-logos/145.svg",
    "DET": "https://www.mlbstatic.com/team-logos/116.svg",
    "HOU": "https://www.mlbstatic.com/team-logos/117.svg",
    "KC": "https://www.mlbstatic.com/team-logos/118.svg",
    "LAA": "https://www.mlbstatic.com/team-logos/108.svg",
    "LAD": "https://www.mlbstatic.com/team-logos/119.svg",
    "MIA": "https://www.mlbstatic.com/team-logos/146.svg",
    "MIL": "https://www.mlbstatic.com/team-logos/158.svg",
    "MIN": "https://www.mlbstatic.com/team-logos/142.svg",
    "NYM": "https://www.mlbstatic.com/team-logos/121.svg",
    "NYY": "https://www.mlbstatic.com/team-logos/147.svg",
    "OAK": "https://www.mlbstatic.com/team-logos/133.svg",
    "PHI": "https://www.mlbstatic.com/team-logos/143.svg",
    "PIT": "https://www.mlbstatic.com/team-logos/134.svg",
    "SD": "https://www.mlbstatic.com/team-logos/135.svg",
    "SEA": "https://www.mlbstatic.com/team-logos/136.svg",
    "SF": "https://www.mlbstatic.com/team-logos/137.svg",
    "STL": "https://www.mlbstatic.com/team-logos/138.svg",
    "TB": "https://www.mlbstatic.com/team-logos/139.svg",
    "TEX": "https://www.mlbstatic.com/team-logos/140.svg",
    "TOR": "https://www.mlbstatic.com/team-logos/141.svg",
    "WAS": "https://www.mlbstatic.com/team-logos/120.svg"
};

const StartingPitcherStats = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [teamFilter, setTeamFilter] = useState('');
    const [sortField, setSortField] = useState('era');
    const [sortDirection, setSortDirection] = useState('asc');
    const lastElementRef = useRef(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = async () => {
        setLoading(true);  // Ensure loading state is set to true before fetching data
        try {
            const response = await axios.get('https://zeusanalytics.org/api/starting-pitcher-stats/', {
                params: {
                    page: page,
                    team: teamFilter,
                    sort: sortField,
                    direction: sortDirection
                }
            });
            if (response.data.results) {
                setData(prevData => [...prevData, ...response.data.results]);
                setHasMore(response.data.next !== null);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);  // Set the authenticated state
                    if (isSubscribed) {
                        fetchData();
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [page, teamFilter, sortField, sortDirection]);

    const loadMore = () => {
        if (hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handleTeamFilterChange = (event) => {
        setTeamFilter(event.target.value);
        setPage(1);
        setData([]);
    };

    const handleSort = (field) => {
        const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
        setPage(1);
        setData([]);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                loadMore();
            }
        }, { threshold: 1.0 });

        if (lastElementRef.current) {
            observer.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.unobserve(lastElementRef.current);
            }
        };
    }, [loading, hasMore]);

    if (isAuthenticated === null) {
        return <div>Loading...</div>;  // Display loading until the authentication status is determined
    }

    return (
        <div className="exit-velocity">
            <h1>Starting Pitcher Stats</h1>
            <div className="filters-container">
                <h2>Filters</h2>
                <div className="filters">
                    <div className="team-filter">
                        <label>
                            Team:
                            <select value={teamFilter} onChange={handleTeamFilterChange}>
                                <option value="">All Teams</option>
                                {Object.keys(teamNameToLinkDict).map(team => (
                                    <option key={team} value={team}>{team}</option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table className="modern-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('pitcher_name')}>Pitcher Name {sortField === 'pitcher_name' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('team')}>Team {sortField === 'team' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('opp_team')}>Opponent Team {sortField === 'opp_team' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('era')}>ERA {sortField === 'era' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('win_loss')}>Win-Loss {sortField === 'win_loss' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('actual_hr')}>Actual HR {sortField === 'actual_hr' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('x_hr')}>Expected HR {sortField === 'x_hr' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('avg_pitches')}>Avg Pitches {sortField === 'avg_pitches' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('avg_hits')}>Avg Hits {sortField === 'avg_hits' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('avg_walks')}>Avg Walks {sortField === 'avg_walks' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{row.pitcher_name}</td>
                                <td><img src={teamNameToLinkDict[row.team]} alt={row.team} className="team-logo-ev" /> {row.team}</td>
                                <td><img src={teamNameToLinkDict[row.opp_team]} alt={row.opp_team} className="team-logo-ev" /> {row.opp_team}</td>
                                <td>{row.era}</td>
                                <td>{row.win_loss}</td>
                                <td>{row.actual_hr}</td>
                                <td>{row.x_hr}</td>
                                <td>{row.avg_pitches}</td>
                                <td>{row.avg_hits}</td>
                                <td>{row.avg_walks}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loading && <div>Loading...</div>}
                <div ref={lastElementRef} />
            </div>
        </div>
    );
};

export default StartingPitcherStats;