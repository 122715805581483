// src/NFLHitRates.jsx

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './NFLHitRates.css';

// Mapping of market names for user-friendly display
const marketNameMap = {
  player_pass_attempts: 'Pass Attempts',
  player_pass_completions: 'Pass Completions',
  player_pass_interceptions: 'Pass Interceptions',
  player_pass_tds: 'Pass TDs',
  player_pass_yds: 'Pass Yards',
  player_receptions: 'Receptions',
  player_reception_yds: 'Reception Yards',
  player_rush_attempts: 'Rush Attempts',
  player_rush_yds: 'Rush Yards',
  player_anytime_td: 'Anytime TD',
  player_tds_over: 'TDs',
};

// Function to combine Outcome, Point, and Market
const getPropText = (outcome, point, market) => {
  const marketName = marketNameMap[market] || market;
  if (point) {
    return `${outcome} ${point} ${marketName}`;
  }
  return `${outcome} ${marketName}`;
};

// Function to dynamically generate color based on hit rate percentage
const getColorForRate = (rate) => {
  // rate is between 0 and 1
  const hue = rate * 120; // Green to red
  return `hsl(${hue}, 75%, 50%)`;
};

// Function to parse odds input and detect format
function parseOdds(odds) {
  if (typeof odds === 'string') {
    odds = odds.trim();
    if (odds.startsWith('+') || odds.startsWith('-')) {
      // American odds
      return { type: 'american', value: odds };
    } else if (!isNaN(parseFloat(odds))) {
      // Assume decimal odds
      return { type: 'decimal', value: parseFloat(odds) };
    }
  }
  return null;
}

// Function to convert odds to decimal odds
function oddsToDecimal(odds) {
  if (odds.type === 'american') {
    const oddsNum = parseFloat(odds.value);
    if (oddsNum > 0) {
      return (oddsNum / 100) + 1;
    } else if (oddsNum < 0) {
      return (100 / -oddsNum) + 1;
    } else {
      return null; // Invalid odds
    }
  } else if (odds.type === 'decimal') {
    return odds.value;
  }
  return null;
}

const NFLHitRates = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortField, setSortField] = useState('hit_rate_last_5');
  const [sortDirection, setSortDirection] = useState('desc');
  const [marketsFilter, setMarketsFilter] = useState(Object.keys(marketNameMap));
  const [sportsbooksFilter, setSportsbooksFilter] = useState([]);
  const [availableSportsbooks, setAvailableSportsbooks] = useState([]);
  const [hitRateFilter, setHitRateFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [minOdds, setMinOdds] = useState('');
  const [maxOdds, setMaxOdds] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Subscription check
  const [showBackToTop, setShowBackToTop] = useState(false);
  const lastElementRef = useRef(null);

  // Subscription check logic
  const checkSubscriptionStatus = async (token) => {
    try {
      const response = await axios.get('/api/check-subscription/', {
        params: { token }
      });
      const subscriptions = response.data;
      const currentTime = new Date().toISOString();

      const isSubscribed = subscriptions.some(subscription =>
        subscription.price.product.capper.id === 320906 &&
        subscription.paid_until > currentTime
      );

      return isSubscribed;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false;
    }
  };

  useEffect(() => {
    axios.get('/profile/')
      .then(response => {
        const { access_token } = response.data;
        checkSubscriptionStatus(access_token).then(isSubscribed => {
          setIsAuthenticated(isSubscribed);  // Set the authenticated state
          if (isSubscribed) {
            fetchData(); // Fetch data if the user is subscribed
          } else {
            window.location.href = 'https://dubclub.win/r/Zeus_Analytics/'; // Redirect if not subscribed
          }
        });
      })
      .catch(error => {
        console.error('Error fetching profile data:', error);
        window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/'; // Redirect if there’s an error fetching the profile
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch data from the API
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/nfl-hit-rates', {
        params: {
          page: page,
          sort: sortField,
          direction: sortDirection
        }
      });
      const responseData = response.data.results;

      // Extract all unique sportsbooks from the data
      const sportsbooksSet = new Set(responseData.map(item => item.sportsbook));
      const allSportsbooks = Array.from(sportsbooksSet);

      // Process data to group similar player props
      const processData = (data) => {
        const grouped = {};
        data.forEach(item => {
          const key = `${item.name}-${item.outcome}-${item.point}-${item.market}`;
          if (!grouped[key]) {
            grouped[key] = { ...item, sportsbooks: [] };
          }
          // Check if sportsbook already exists
          if (!grouped[key].sportsbooks.some(sb => sb.name === item.sportsbook)) {
            grouped[key].sportsbooks.push({
              name: item.sportsbook,
              odds: item.odds
            });
          }
        });

        // Set the best sportsbook by default
        Object.values(grouped).forEach(group => {
          group.selectedSportsbook = group.sportsbooks.reduce((best, current) => {
            const currentOdds = parseFloat(current.odds);
            const bestOdds = parseFloat(best.odds);
            if ((currentOdds > bestOdds && currentOdds > 0) || (currentOdds < bestOdds && currentOdds < 0)) {
              return current;
            }
            return best;
          }, group.sportsbooks[0]);
        });

        return Object.values(grouped);
      };

      setData(prevData => {
        const newData = processData([...prevData, ...responseData]);
        return newData;
      });
      setAvailableSportsbooks(prevAvailableSportsbooks => Array.from(new Set([...prevAvailableSportsbooks, ...allSportsbooks])));
      if (page === 1) {
        setSportsbooksFilter(allSportsbooks); // Default: all sportsbooks selected
      }
      setHasMore(response.data.next !== null);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching NFL hit rates data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortField, sortDirection, isAuthenticated]);

  const loadMore = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
    setPage(1);
    setData([]);
  };

  const handleMarketChange = (market) => {
    if (marketsFilter.includes(market)) {
      setMarketsFilter(marketsFilter.filter(m => m !== market));
    } else {
      setMarketsFilter([...marketsFilter, market]);
    }
  };

  const handleSportsbookChange = (sportsbook) => {
    if (sportsbooksFilter.includes(sportsbook)) {
      setSportsbooksFilter(sportsbooksFilter.filter(s => s !== sportsbook));
    } else {
      setSportsbooksFilter([...sportsbooksFilter, sportsbook]);
    }
  };

  const handleHitRateChange = (rate) => {
    setHitRateFilter(rate);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        loadMore();
      }
    }, { threshold: 1.0 });

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, hasMore]);

  const handleSportsbookSelection = (rowKey, selectedSb) => {
    setData(prevData => prevData.map(item => {
      const key = `${item.name}-${item.outcome}-${item.point}-${item.market}`;
      if (key === rowKey) {
        return { ...item, selectedSportsbook: selectedSb };
      }
      return item;
    }));
  };

  // Back to top button visibility
  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Updated filtering to handle hit rate and sportsbooks correctly
  const filteredData = data
    .map(item => {
      const marketIncluded = marketsFilter.includes(item.market);
      const nameMatches = item.name.toLowerCase().includes(searchQuery.toLowerCase());
      const hitRateLast5Percent = (item.hit_rate_last_5 / 5) * 100;
      const hitRateIncluded = hitRateFilter === 'All' || hitRateLast5Percent === hitRateFilter;

      // Filter the sportsbooks for this item to only include those in sportsbooksFilter
      const filteredSportsbooks = item.sportsbooks.filter(sb => sportsbooksFilter.includes(sb.name));

      // If no sportsbooks left after filtering, exclude this item
      if (filteredSportsbooks.length === 0) {
        return null;
      }

      // Update selectedSportsbook among the filteredSportsbooks
      let selectedSportsbook = item.selectedSportsbook;
      if (!sportsbooksFilter.includes(selectedSportsbook.name)) {
        // Select a new selectedSportsbook among the filteredSportsbooks
        selectedSportsbook = filteredSportsbooks.reduce((best, current) => {
          const currentOdds = parseFloat(current.odds);
          const bestOdds = parseFloat(best.odds);
          if ((currentOdds > bestOdds && currentOdds > 0) || (currentOdds < bestOdds && currentOdds < 0)) {
            return current;
          }
          return best;
        }, filteredSportsbooks[0]);
      }

      // Parse user's min and max odds
      const parsedMinOdds = parseOdds(minOdds);
      const parsedMaxOdds = parseOdds(maxOdds);

      const minOddsDecimal = parsedMinOdds ? oddsToDecimal(parsedMinOdds) : null;
      const maxOddsDecimal = parsedMaxOdds ? oddsToDecimal(parsedMaxOdds) : null;

      // Check if any of the filtered sportsbooks have odds within the specified range
      const oddsIncluded = filteredSportsbooks.some(sb => {
        const sbOddsParsed = parseOdds(sb.odds);
        const sbOddsDecimal = oddsToDecimal(sbOddsParsed);

        return sbOddsDecimal !== null &&
          (!minOddsDecimal || sbOddsDecimal >= minOddsDecimal) &&
          (!maxOddsDecimal || sbOddsDecimal <= maxOddsDecimal);
      });

      if (!marketIncluded || !nameMatches || !hitRateIncluded || !oddsIncluded) {
        return null;
      }

      // Return a new item with updated sportsbooks and selectedSportsbook
      return {
        ...item,
        sportsbooks: filteredSportsbooks,
        selectedSportsbook: selectedSportsbook
      };
    })
    .filter(item => item !== null);

  if (isAuthenticated === null) {
    return (
      <div className="nfl-hit-rates">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="nfl-hit-rates">
      <h1>NFL Hit Rates</h1>

      {/* Filters in a bar with dropdowns */}
      <div className="nfl-filter-bar">
        <div className="nfl-dropdown">
          <button className="nfl-dropbtn">Markets</button>
          <div className="nfl-dropdown-content">
            {Object.keys(marketNameMap).map((market) => (
              <label key={market}>
                <input
                  type="checkbox"
                  checked={marketsFilter.includes(market)}
                  onChange={() => handleMarketChange(market)}
                />
                {marketNameMap[market]}
              </label>
            ))}
          </div>
        </div>

        <div className="nfl-dropdown">
          <button className="nfl-dropbtn">Sportsbooks</button>
          <div className="nfl-dropdown-content">
            {availableSportsbooks.map((sportsbook) => (
              <label key={sportsbook}>
                <input
                  type="checkbox"
                  checked={sportsbooksFilter.includes(sportsbook)}
                  onChange={() => handleSportsbookChange(sportsbook)}
                />
                {sportsbook}
              </label>
            ))}
          </div>
        </div>

        <div className="nfl-dropdown">
          <button className="nfl-dropbtn">Hit Rate</button>
          <div className="nfl-dropdown-content">
            <label>
              <input
                type="radio"
                name="hitRate"
                checked={hitRateFilter === 'All'}
                onChange={() => handleHitRateChange('All')}
              />
              All
            </label>
            {[20, 40, 60, 80, 100].map((rate) => (
              <label key={rate}>
                <input
                  type="radio"
                  name="hitRate"
                  checked={hitRateFilter === rate}
                  onChange={() => handleHitRateChange(rate)}
                />
                {rate}%
              </label>
            ))}
          </div>
        </div>

        {/* Search Input */}
        <input
          type="text"
          placeholder="Search Player Names"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="nfl-search-input"
        />

        {/* Odds Filter Inputs */}
        <div className="nfl-odds-filter">
          <input
            type="text"
            placeholder="Min Odds (+100 or 2.0)"
            value={minOdds}
            onChange={(e) => setMinOdds(e.target.value)}
            className="nfl-odds-input"
          />
          <input
            type="text"
            placeholder="Max Odds (-150 or 1.67)"
            value={maxOdds}
            onChange={(e) => setMaxOdds(e.target.value)}
            className="nfl-odds-input"
          />
        </div>
      </div>

      <div className="nfl-table-container">
        <table className="nfl-modern-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('name')}>Player Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th>Prop</th>
              <th onClick={() => handleSort('odds')}>Odds {sortField === 'odds' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th>Sportsbook</th>
              <th onClick={() => handleSort('hit_rate_last_5')}>Last 5 Hits {sortField === 'hit_rate_last_5' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('hit_rate_last_10')}>Last 10 Hits {sortField === 'hit_rate_last_10' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
              <th onClick={() => handleSort('hit_rate_season')}>Season Hits {sortField === 'hit_rate_season' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
            </tr>
          </thead>
          <tbody>
            {loading && data.length === 0 ? (
              <tr><td colSpan="7"><div className="spinner"></div></td></tr>
            ) : (
              filteredData.map((row, index) => {
                const rowKey = `${row.name}-${row.outcome}-${row.point}-${row.market}`;
                const hitRateLast5Rate = row.hit_rate_last_5 / 5;
                const hitRateLast10Rate = row.hit_rate_last_10 / 10;
                let seasonHits, seasonTotal, hitRateSeasonRate;
                if (typeof row.hit_rate_season === 'string') {
                  [seasonHits, seasonTotal] = row.hit_rate_season.split('/').map(Number);
                  hitRateSeasonRate = seasonHits / seasonTotal;
                } else {
                  seasonHits = row.hit_rate_season;
                  seasonTotal = 1; // Avoid division by zero
                  hitRateSeasonRate = 1;
                }

                return (
                  <tr key={index}>
                    <td>{row.name}</td>
                    <td>{getPropText(row.outcome, row.point, row.market)}</td>
                    <td>{row.selectedSportsbook.odds.startsWith('-') ? row.selectedSportsbook.odds : `+${row.selectedSportsbook.odds}`}</td>
                    <td>
                      <div className="nfl-sportsbook-logos">
                        {row.sportsbooks.map((sb, idx) => (
                          <img
                            key={idx}
                            src={
                              sb.name === 'BetRivers' ? 'https://gamblespot-images.s3-accelerate.amazonaws.com/images/da4f2a57-b848-475a-b4f8-ae101faca807_30-08-2024-22-03-11.webp' :
                              sb.name === 'Caesars' ? 'https://img.sportsbookreview.com/images/sportsbooks/logos/caesars-logo.png' :
                              sb.name === 'FanDuel' ? 'https://pbs.twimg.com/profile_images/1562799602008576000/nDltoq9L_400x400.jpg' :
                              sb.name === 'BetOnline.ag' ? 'https://pbs.twimg.com/profile_images/1527009029389697025/O0mS1E9Y_400x400.jpg' :
                              sb.name === 'BetMGM' ? 'https://www.sportsvideo.org/wp-content/uploads/2020/03/BetMGM-Logo-%E2%80%93-HiRes.png' :
                              sb.name === 'DraftKings' ? 'https://play-lh.googleusercontent.com/9SmQILg4QMVVM1yzSfUvO1KVRkTJc6ckynDuGqDg2oEnjcqcW1ERsQlfNbczBXUy8OZt' :
                              sb.name === 'Bovada' ? 'https://sportsbetting.legal/wp-content/uploads/2021/06/Bovada-mobile2.png' :
                              'https://via.placeholder.com/24'
                            }
                            alt={sb.name}
                            className={`nfl-sportsbook-logo ${sb.name === row.selectedSportsbook.name ? 'selected' : ''}`}
                            onClick={() => handleSportsbookSelection(rowKey, sb)}
                            title="Click to view odds from this sportsbook"
                          />
                        ))}
                      </div>
                    </td>
                    <td style={{ backgroundColor: getColorForRate(hitRateLast5Rate) }}>
                      {row.hit_rate_last_5}/5
                    </td>
                    <td style={{ backgroundColor: getColorForRate(hitRateLast10Rate) }}>
                      {row.hit_rate_last_10}/10
                    </td>
                    <td style={{ backgroundColor: getColorForRate(hitRateSeasonRate) }}>
                      {row.hit_rate_season}
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {loading && data.length > 0 && <div className="spinner"></div>}
        <div ref={lastElementRef} />
      </div>

      {/* Back to Top Button */}
      {showBackToTop && (
        <button className="back-to-top" onClick={scrollToTop}>
          ↑
        </button>
      )}
    </div>
  );
};

export default NFLHitRates;
