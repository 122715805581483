import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TopTDPredictions.css';  // Ensure your CSS file has the updated styles
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const teamColorDict = {
    "Chiefs": "#E31837",     // Primary Red for Kansas City Chiefs
    "Packers": "#203731",    // Dark Green for Green Bay Packers
    "Eagles": "#004C54",     // Dark Green for Philadelphia Eagles
    "Bills": "#00338D",      // Royal Blue for Buffalo Bills
    "Dolphins": "#008E97",   // Aqua for Miami Dolphins
    "Colts": "#002C5F",      // Navy Blue for Indianapolis Colts
    "Vikings": "#4F2683",    // Purple for Minnesota Vikings
    "Saints": "#D3BC8D",     // Old Gold for New Orleans Saints
    "Seahawks": "#002244",   // Navy Blue for Seattle Seahawks
    "Raiders": "#A5ACAF",    // Silver for Las Vegas Raiders
    "Chargers": "#0073CF",   // Powder Blue for Los Angeles Chargers
    "Bucs": "#D50A0A",       // Red for Tampa Bay Buccaneers
    "Lions": "#0076B6",      // Honolulu Blue for Detroit Lions
    "Rams": "#003594",       // Royal Blue for Los Angeles Rams
    "Panthers": "#0085CA",   // Carolina Blue for Carolina Panthers
    "Cardinals": "#97233F",  // Cardinal Red for Arizona Cardinals
    "Bengals": "#FB4F14",    // Orange for Cincinnati Bengals
    "Cowboys": "#041E42",    // Navy Blue for Dallas Cowboys
    "Broncos": "#FB4F14",    // Orange for Denver Broncos
    "Texans": "#03202F",     // Deep Steel Blue for Houston Texans
    "Giants": "#0B2265",     // Dark Blue for New York Giants
    "Jets": "#125740",       // Gotham Green for New York Jets
    "Bears": "#0B162A",      // Navy Blue for Chicago Bears
    "Falcons": "#A71930",    // Red for Atlanta Falcons
    "49ers": "#AA0000",      // Scarlet for San Francisco 49ers
    "Browns": "#311D00",     // Brown for Cleveland Browns
    "Ravens": "#241773",     // Purple for Baltimore Ravens
    "Commanders": "#5A1414", // Burgundy for Washington Commanders
    "Titans": "#4B92DB",     // Titans Blue for Tennessee Titans
    "Patriots": "#002244",   // Navy Blue for New England Patriots
    "Steelers": "#FFB612",    // Yellow for Pittsburgh Steelers
    "Jaguars": "#006778",
    "Jags": "#006778"
};

const TopTDPredictions = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const minDate = new Date(Date.UTC(2024, 7, 17, 0, 0, 0));
    const maxDate = new Date();

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchPredictions = async (date) => {
        setLoading(true);
        try {
            const response = await axios.get('https://zeusanalytics.org/api/top-td-predictions-history/', {
                params: { date: date.toISOString().split('T')[0] }
            });
            if (response.data && response.data.results) {
                setData(response.data.results);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        fetchPredictions(date);
        setIsDatePickerOpen(false); // Close date picker after date selection
    };

    const toggleDatePicker = () => {
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the date picker visibility
    };

    useEffect(() => {
        const getEasternTime = () => {
            const options = {
                timeZone: 'America/New_York',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            };
            const formatter = new Intl.DateTimeFormat([], options);
            const parts = formatter.formatToParts(new Date());

            const month = parts.find(p => p.type === 'month').value;
            const day = parts.find(p => p.type === 'day').value;
            const year = parts.find(p => p.type === 'year').value;

            return new Date(year, month - 1, day);
        };

        const easternTimeDate = getEasternTime();
        setSelectedDate(easternTimeDate);

        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);
                    if (isSubscribed) {
                        fetchPredictions(easternTimeDate);
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    if (loading) {
        return <div className="top-td-predictions-loading">Loading...</div>;
    }

    if (isAuthenticated === null) {
        return <div className="top-td-predictions-loading">Loading...</div>;
    }

    return (
        <div className="top-td-predictions-container">
            <h1>The Script</h1>
            <div className="top-td-predictions-date-picker">
                {!isDatePickerOpen && (
                    <FaCalendarAlt className="top-td-predictions-calendar-icon" onClick={toggleDatePicker} />
                )}
                {isDatePickerOpen && (
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        maxDate={maxDate}
                        minDate={minDate}
                        dateFormat="yyyy-MM-dd"
                        inline
                        className="top-td-predictions-date-picker-input"
                    />
                )}
            </div>
            <h2 className="top-td-predictions-selected-date">
                {selectedDate ? selectedDate.toDateString() : ''}
            </h2>
            <div className="top-td-predictions-table-container">
                <table className="top-td-predictions-table">
                    <thead>
                        <tr>
                            <th>Player</th>
                            <th>Team</th>
                            <th>AI Boost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <div className="top-td-predictions-player-info">
                                        <img src={row.player_picture} alt={`${row.player}`} className="top-td-predictions-player-picture" />
                                        <span>{row.player}</span>
                                    </div>
                                </td>
                                <td
                                    style={{
                                        backgroundColor: teamColorDict[row.player_team],
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '1.2em',
                                        padding: '10px'
                                    }}
                                >
                                    {row.player_team}
                                </td>
                                <td className="top-td-predictions-gradient-text">{(row.prediction_score).toFixed(1)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TopTDPredictions;