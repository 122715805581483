import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InningFirstStats.css';

const InningFirstStats = () => {
    const [stats, setStats] = useState([]);
    const [gameOrder, setGameOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription =>
                subscription.price.product.capper.id === 320906 &&
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = async () => {
        try {
            const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            const [inningFirstResponse, scheduleResponse] = await Promise.all([
                axios.get('/api/inningfirst/'),
                axios.get(`https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${currentDate}`)
            ]);


            const inningFirstData = inningFirstResponse.data.results || [];
            setStats(inningFirstData);

            const games = scheduleResponse.data.events;
            const gameOrder = games.map(game => {
                const homeTeam = game.competitions[0].competitors.find(comp => comp.homeAway === "home").team.abbreviation;
                const awayTeam = game.competitions[0].competitors.find(comp => comp.homeAway === "away").team.abbreviation;
                const startTime = new Date(game.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

                return {
                    awayTeam,
                    homeTeam,
                    date: new Date(game.date),
                    startTime
                };
            });
            setGameOrder(gameOrder);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);
                    if (isSubscribed) {
                        fetchData();
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className="inning-first-stats">
            <h1>1st Inning Stats</h1>
            {loading && <div>Loading...</div>}
            {!loading && stats.length === 0 && <div>No data available.</div>}
            {!loading && stats.length > 0 && stats.map((stat, index) => (
                <div className="stat-entry" key={index}>
                    <div className="team-header">
                        <span className="team-name">{stat.pitcher_name}</span> | 
                        <span className="team-name">{stat.pitcher_team}</span> vs 
                        <span className="team-name">{stat.opp_team}</span>
                    </div>
                    <table className="stats-table">
                        <thead>
                            <tr>
                                <th>Avg Pitches Thrown</th>
                                <th>Avg Strikes Thrown</th>
                                <th>Avg Fouls Thrown</th>
                                <th>Avg Balls Thrown</th>
                                <th>Avg Pitches Seen</th>
                                <th>Avg Strikes Seen</th>
                                <th>Avg Fouls Seen</th>
                                <th>Avg Balls Seen</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{stat.avg_pitches_thrown}</td>
                                <td>{stat.avg_strikes_thrown}</td>
                                <td>{stat.avg_fouls_thrown}</td>
                                <td>{stat.avg_balls_thrown}</td>
                                <td>{stat.avg_pitches_seen}</td>
                                <td>{stat.avg_strikes_seen}</td>
                                <td>{stat.avg_fouls_seen}</td>
                                <td>{stat.avg_balls_seen}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
};

export default InningFirstStats;
