import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs'; // Added qs for custom param serialization
import './NbaThreePointerHub.css';

const NbaThreePointerHub = () => {
  const [games, setGames] = useState([]);
  const [expandedGameId, setExpandedGameId] = useState(null);
  const [playerDataMap, setPlayerDataMap] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const dateStr = `${yyyy}${mm}${dd}`;

        // Fetch today's scoreboard
        const scoreboardUrl = `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard?dates=${dateStr}`;
        const scoreboardResponse = await axios.get(scoreboardUrl);

        const events = scoreboardResponse.data.events || [];
        const gamesData = events.map(event => {
          const competition = event.competitions[0];
          const homeTeam = competition.competitors.find(c => c.homeAway === 'home');
          const awayTeam = competition.competitors.find(c => c.homeAway === 'away');
          return {
            id: event.id,
            date: event.date,
            homeTeam: {
              name: homeTeam.team.displayName,
              abbrev: homeTeam.team.abbreviation,
              logo: homeTeam.team.logo,
            },
            awayTeam: {
              name: awayTeam.team.displayName,
              abbrev: awayTeam.team.abbreviation,
              logo: awayTeam.team.logo,
            }
          };
        });

        setGames(gamesData);
      } catch (error) {
        console.error('Error fetching scoreboard:', error);
      }
    };

    fetchData();
  }, []);

  const toggleExpand = async (gameId, homeTeam, awayTeam) => {
    if (expandedGameId === gameId) {
      // Collapse
      setExpandedGameId(null);
      return;
    }

    try {
      // Fetch player prop odds for threes (Over) from FanDuel
      const oddsResponse = await axios.get('/api/nba-player-prop-odds/', {
        params: {
          market: 'player_threes',
          outcome: 'Over',
          sort: 'name',
          direction: 'asc',
          sportsbook: 'FanDuel'
        }
      });

      const playerOdds = oddsResponse.data.results;

      // Extract player names
      const playerNames = playerOdds.map(p => p.name);

      // Fetch three stats using qs for proper array serialization
      const threeStatsResponse = await axios.get('/api/nba-gen-player-stats/three-stats/', {
        params: {
          player_name: playerNames
        },
        // Use qs to serialize arrays as repeated params: player_name=Name1&player_name=Name2
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
      });

      const threeStats = threeStatsResponse.data; // Array of objects with player stats

      // Build a map: player_name_lower -> three stats data
      const threeStatsMap = {};
      threeStats.forEach(p => {
        threeStatsMap[p.player_name.toLowerCase()] = p;
      });

      const awayPlayers = [];
      const homePlayers = [];

      // Filter players by home/away team and add their stats
      playerOdds.forEach(p => {
        const stats = threeStatsMap[p.name.toLowerCase()];
        if (!stats) return; // Player not found in stats, skip

        // Check if player's team matches home or away
        if (stats.team === homeTeam.abbrev) {
          homePlayers.push({
            ...p,
            ...stats
          });
        } else if (stats.team === awayTeam.abbrev) {
          awayPlayers.push({
            ...p,
            ...stats
          });
        }
      });

      setPlayerDataMap(prev => ({
        ...prev,
        [gameId]: { awayPlayers, homePlayers }
      }));

      setExpandedGameId(gameId);
    } catch (error) {
      console.error('Error fetching odds or three stats:', error);
    }
  };

  return (
    <div className="three-pointer-hub">
      <h1>NBA Three Pointer Hub</h1>
      {games.map(game => (
        <div key={game.id} className="game-container">
          <div className="game-header" onClick={() => toggleExpand(game.id, game.homeTeam, game.awayTeam)}>
            <div className="team-info">
              <img src={game.awayTeam.logo} alt={game.awayTeam.name} className="team-logo" />
              <span>{game.awayTeam.name}</span>
            </div>
            <span> @ </span>
            <div className="team-info">
              <img src={game.homeTeam.logo} alt={game.homeTeam.name} className="team-logo" />
              <span>{game.homeTeam.name}</span>
            </div>
          </div>
          {expandedGameId === game.id && playerDataMap[game.id] && (
            <div className="game-details">
              <div className="players-column">
                <h2>{game.awayTeam.abbrev}</h2>
                {playerDataMap[game.id].awayPlayers.length > 0 ? (
                  playerDataMap[game.id].awayPlayers.map(player => (
                    <div key={player.name} className="player-row">
                      <img src={player.profile_picture} alt={player.name} className="player-pic" />
                      <div className="player-info">
                        <div className="player-name">{player.name}</div>
                        <div>Season Avg 3PM: {player.season_avg_3pm.toFixed(1)}</div>
                        <div>Season Avg 3PA: {player.season_avg_3pa.toFixed(1)}</div>
                        <div>3P%: {(player.season_3p_pct * 100).toFixed(1)}%</div>
                        <div>Δ 3PM (L7 - Season): {player.delta_3pm >= 0 ? `+${player.delta_3pm.toFixed(1)}` : player.delta_3pm.toFixed(1)}</div>
                        <div>3's Prop line (FanDuel): Over {player.point} {player.odds}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No player threes Over props found for away team</div>
                )}
              </div>
              <div className="players-column">
                <h2>{game.homeTeam.abbrev}</h2>
                {playerDataMap[game.id].homePlayers.length > 0 ? (
                  playerDataMap[game.id].homePlayers.map(player => (
                    <div key={player.name} className="player-row">
                      <img src={player.profile_picture} alt={player.name} className="player-pic" />
                      <div className="player-info">
                        <div className="player-name">{player.name}</div>
                        <div>Season Avg 3PM: {player.season_avg_3pm.toFixed(1)}</div>
                        <div>Season Avg 3PA: {player.season_avg_3pa.toFixed(1)}</div>
                        <div>3P%: {(player.season_3p_pct * 100).toFixed(1)}%</div>
                        <div>Δ 3PM (L7 - Season): {player.delta_3pm >= 0 ? `+${player.delta_3pm.toFixed(1)}` : player.delta_3pm.toFixed(1)}</div>
                        <div>3's Prop line (FanDuel): Over {player.point} {player.odds}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No player threes Over props found for home team</div>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default NbaThreePointerHub;
