import React from 'react';
import './MLBDefinitions.css';

const MLBDefinitions = () => {
  return (
    <div className="definitions-container">
      <h1>MLB Definitions</h1>
      <div className="card-grid">
        <div className="card">
          <h2>Exit Velocity</h2>
          <p>Exit Velocity measures the speed of the baseball as it comes off the bat, immediately after a batter makes contact. This is tracked for all Batted Ball Events -- outs, hits and errors.</p>
          <p>Attaining a high Exit Velocity is one of a hitter's primary goals. A hard-hit ball won't always have a positive result, but the defense has less time to react, so the batter's chances of reaching base are higher.</p>
          <p>For this very reason, Exit Velocity can also be used to evaluate pitchers (known as "Exit Velocity Against"). The game's best pitchers -- who naturally throw the highest quality pitches -- generally rank among the league leaders at limiting hard contact.</p>
          <p><strong>Average Exit Velocity (aEV)</strong> is calculated by dividing the sum of all Exit Velocities by all Batted Ball Events.</p>
        </div>
        <div className="card">
          <h2>Launch Angles</h2>
          <p>Launch Angle represents the vertical angle at which the ball leaves a player's bat after being struck. Average Launch Angle (aLA) is calculated by dividing the sum of all Launch Angles by all Batted Ball Events.</p>
          <p>As a guideline, here are the Launch Angles for different types of contact:</p>
          <ul>
            <li><strong>Ground ball:</strong> Less than 10 degrees</li>
            <li><strong>Line drive:</strong> 10-25 degrees</li>
            <li><strong>Fly ball:</strong> 25-50 degrees</li>
            <li><strong>Pop up:</strong> Greater than 50 degrees</li>
          </ul>
        </div>
        {/* Add more cards as needed */}
      </div>
    </div>
  );
};

export default MLBDefinitions;
