// WhosHotNBA.js

import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './axiosInstance'; // Adjust the path as necessary
import './WhosHotNBA.css';
import { unidecode } from 'unidecode'; // Import unidecode to remove accents

const teamAbbreviationToName = {
  ATL: 'Atlanta Hawks',
  BOS: 'Boston Celtics',
  BKN: 'Brooklyn Nets',
  CHA: 'Charlotte Hornets',
  CHI: 'Chicago Bulls',
  CLE: 'Cleveland Cavaliers',
  DAL: 'Dallas Mavericks',
  DEN: 'Denver Nuggets',
  DET: 'Detroit Pistons',
  GSW: 'Golden State Warriors',
  HOU: 'Houston Rockets',
  IND: 'Indiana Pacers',
  LAC: 'LA Clippers',
  LAL: 'Los Angeles Lakers',
  MEM: 'Memphis Grizzlies',
  MIA: 'Miami Heat',
  MIL: 'Milwaukee Bucks',
  MIN: 'Minnesota Timberwolves',
  NOP: 'New Orleans Pelicans',
  NYK: 'New York Knicks',
  OKC: 'Oklahoma City Thunder',
  ORL: 'Orlando Magic',
  PHI: 'Philadelphia 76ers',
  PHX: 'Phoenix Suns',
  POR: 'Portland Trail Blazers',
  SAC: 'Sacramento Kings',
  SAS: 'San Antonio Spurs',
  TOR: 'Toronto Raptors',
  UTA: 'Utah Jazz',
  WAS: 'Washington Wizards',
};

const WhosHotNBA = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [teamFilter, setTeamFilter] = useState('');
  const [sortField, setSortField] = useState('num_20pt_games');
  const [sortDirection, setSortDirection] = useState('desc');
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [currentDate, setCurrentDate] = useState('');
  const lastElementRef = useRef(null);

  const defaultProfilePictureUrl = 'https://yourserver.com/default-profile-picture.png'; // Replace with your default image URL

  const checkSubscriptionStatus = async (token) => {
    try {
      const response = await axiosInstance.get('/api/check-subscription/', {
        params: { token },
      });
      const subscriptions = response.data;
      const currentTime = new Date().toISOString();

      const isSubscribed = subscriptions.some(
        (subscription) =>
          subscription.price.product.capper.id === 320906 &&
          subscription.paid_until > currentTime
      );

      return isSubscribed;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false;
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        page: page,
        team: teamFilter,
        sort: sortField,
        direction: sortDirection,
      };

      // Remove empty parameters
      Object.keys(params).forEach(
        (key) => (params[key] === '' || params[key] === undefined) && delete params[key]
      );

      const response = await axiosInstance.get('/api/nba-whos-hot/', {
        params,
      });

      if (response.data.results) {
        setData((prevData) =>
          page === 1 ? response.data.results : [...prevData, ...response.data.results]
        );
        setHasMore(response.data.next !== null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentDate = () => {
    const now = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return now.toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    setCurrentDate(getCurrentDate());

    axiosInstance
      .get('/profile/')
      .then((response) => {
        const { access_token } = response.data;
        checkSubscriptionStatus(access_token).then((isSubscribed) => {
          setIsAuthenticated(isSubscribed);
          if (isSubscribed) {
            fetchData();
          } else {
            window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching profile data:', error);
        window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, teamFilter, sortField, sortDirection, isAuthenticated]);

  const loadMore = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleTeamFilterChange = (event) => {
    setTeamFilter(event.target.value);
    setPage(1);
    setData([]);
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
    setPage(1);
    setData([]);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [loading, hasMore]);

  if (isAuthenticated === null) {
    return (
      <div className="nwh-loading-container">
        <div className="nwh-spinner"></div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="nba-whos-hot">
      <h1>NBA Who's Hot</h1>
      <h2 className="nwh-date">{currentDate}</h2>
      <div className="nwh-filters-container">
        <h2>Filters</h2>
        <div className="nwh-filters">
          <div className="nwh-filter-group">
            <div className="nwh-team-filter">
              <label>
                Team:
                <select value={teamFilter} onChange={handleTeamFilterChange}>
                  <option value="">All Teams</option>
                  {Object.entries(teamAbbreviationToName).map(([abbr, name]) => (
                    <option key={abbr} value={abbr}>
                      {name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="nwh-table-container">
        <table className="nwh-modern-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('player_name')}>
                Player {sortField === 'player_name' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('team')}>
                Team {sortField === 'team' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('num_20pt_games')}>
                20+ Pt Games<br />(Last 7 Days){' '}
                {sortField === 'num_20pt_games' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('num_30pt_games')}>
                30+ Pt Games<br />(Last 7 Days){' '}
                {sortField === 'num_30pt_games' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('num_40pt_games')}>
                40+ Pt Games<br />(Last 7 Days){' '}
                {sortField === 'num_40pt_games' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('num_50pt_games')}>
                50+ Pt Games<br />(Last 7 Days){' '}
                {sortField === 'num_50pt_games' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_points')}>
                Avg Points<br />(Last 7 Days){' '}
                {sortField === 'avg_points' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              {/* Delta Points Column */}
              <th>
                Δ PTS (L7 - Season)
              </th>
              <th onClick={() => handleSort('avg_rebounds')}>
                Avg Rebounds<br />(Last 7 Days){' '}
                {sortField === 'avg_rebounds' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              {/* Delta Rebounds Column */}
              <th>
                Δ REB (L7 - Season)
              </th>
              <th onClick={() => handleSort('avg_assists')}>
                Avg Assists<br />(Last 7 Days){' '}
                {sortField === 'avg_assists' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              {/* Delta Assists Column */}
              <th>
                Δ AST (L7 - Season)
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <tr key={index}>
                  <td className="nwh-player-cell">
                    <img
                      src={row.profile_picture || defaultProfilePictureUrl}
                      alt={row.player_name}
                      className="nwh-player-picture"
                    />
                    {row.player_name}
                  </td>
                  <td>{teamAbbreviationToName[row.team] || row.team}</td>
                  <td>{row.num_20pt_games}</td>
                  <td>{row.num_30pt_games}</td>
                  <td>{row.num_40pt_games}</td>
                  <td>{row.num_50pt_games}</td>
                  <td>{row.avg_points?.toFixed(1)}</td>
                  {/* Delta Points */}
                  <td className={row.delta_points > 0 ? 'positive-delta' : row.delta_points < 0 ? 'negative-delta' : ''}>
                    {row.delta_points?.toFixed(1)}
                  </td>
                  <td>{row.avg_rebounds?.toFixed(1)}</td>
                  {/* Delta Rebounds */}
                  <td className={row.delta_rebounds > 0 ? 'positive-delta' : row.delta_rebounds < 0 ? 'negative-delta' : ''}>
                    {row.delta_rebounds?.toFixed(1)}
                  </td>
                  <td>{row.avg_assists?.toFixed(1)}</td>
                  {/* Delta Assists */}
                  <td className={row.delta_assists > 0 ? 'positive-delta' : row.delta_assists < 0 ? 'negative-delta' : ''}>
                    {row.delta_assists?.toFixed(1)}
                  </td>
                </tr>
              ))
            ) : (
              !loading && (
                <tr>
                  <td colSpan="12" className="nwh-no-data">
                    No data available for the selected filters.
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
        {loading && (
          <div className="nwh-loading-container">
            <div className="nwh-spinner"></div>
            <div>Loading...</div>
          </div>
        )}
        <div ref={lastElementRef} />
      </div>
    </div>
  );
};

export default WhosHotNBA;
