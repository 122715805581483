// src/components/AwardsPage.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AwardSection from './AwardSection';
import styled from 'styled-components';
import { teamAbbreviationToLogoUrl } from '../utils/teamAbbreviationToLogoUrl';
import { teamAbbreviationToColor } from '../utils/teamColors';
import { DateTime } from 'luxon'; // Import Luxon

// Styled Components for Scroll Container

const ScrollContainer = styled.div`
  height: auto; /* Allow the container to expand with content */
  min-height: 100vh; /* Ensure it doesn't shrink below the viewport height */
  width: 100vw; /* Full width to remove the white margins */
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  background: linear-gradient(135deg, #1c1c1c, #2a2a2a);

  /* Hide scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  /* Prevent horizontal overflow */
  overflow-x: hidden;

  /* Add padding to prevent content from being hidden under navbar */
  padding-top: 60px; /* Adjust based on your navbar */

  box-sizing: border-box;
`;

// Styled Component for each Scroll Section
const ScrollSection = styled.div`
  min-height: 100vh; /* Allow sections to expand if needed */
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  box-sizing: border-box;
  padding: 20px;

  display: flex;
  align-items: flex-start; /* Align items to the start to prevent overflow */
  justify-content: center;

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

// Styled Components for Loading and Error Messages
const LoadingMessage = styled.p`
  color: #ffffff;
  text-align: center;
  margin-top: 50px;
  font-size: 1.2rem;
`;

const ErrorMessage = styled.p`
  color: #ff4d4f;
  text-align: center;
  margin-top: 50px;
  font-size: 1.2rem;
`;

const AwardsPage = () => {
  const [playerAwards, setPlayerAwards] = useState([]);
  const [teamAwards, setTeamAwards] = useState([]);
  const [playerPictures, setPlayerPictures] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Function to get the previous date in Eastern Time (ET)
   * Logic:
   * - If current time in ET is >= 6 AM, fetch yesterday's awards
   * - If current time in ET is < 6 AM, fetch awards from two days ago
   */
  const getPreviousDateEastern = () => {
    const now = DateTime.now().setZone('America/New_York');
    

    const currentHour = now.hour;
    //console.log("hour: " + currentHour);
    let targetDate;

    if (currentHour >= 6) {
      // After 6 AM ET, target is yesterday
      targetDate = now.minus({ days: 0 });
    } else {
      // Before 6 AM ET, target is two days ago
      targetDate = now.minus({ days: 1 });
    }
    //console.log(targetDate.toISODate());
    return targetDate.toISODate(); // Returns 'YYYY-MM-DD'
  };

  // Fetch Awards and Player Pictures
  useEffect(() => {
    const fetchAwardsAndPictures = async () => {
      try {
        const previousDate = getPreviousDateEastern();

        // Fetch Player Awards with date filter
        const playerAwardsResponse = await axios.get('/api/player-awards/', {
          params: {
            start_date: previousDate,
            end_date: previousDate,
          },
        });
        const playerAwardsData = playerAwardsResponse.data.results || [];

        // Fetch Team Awards with date filter
        const teamAwardsResponse = await axios.get('/api/team-awards/', {
          params: {
            start_date: previousDate,
            end_date: previousDate,
          },
        });
        const teamAwardsData = teamAwardsResponse.data.results || [];

        // Fetch Player Pictures
        const playerPicturesResponse = await axios.get('/api/nba-player-pictures/');
        const playerPicturesData = playerPicturesResponse.data.results || [];

        // Create a mapping from player_name (lowercase) to profile_picture
        const picturesMap = {};
        playerPicturesData.forEach(picture => {
          if (picture.player_name && picture.profile_picture) {
            picturesMap[picture.player_name.toLowerCase()] = picture.profile_picture;
          }
        });

        // Merge the player awards data with player pictures and team colors
        const mergedPlayerAwards = playerAwardsData.map(playerAward => {
          const playerNameLower = playerAward.player_name.toLowerCase();
          const teamAbbreviation = playerAward.team || 'N/A'; // Assuming 'team' field exists
          const teamColor =
            teamAbbreviation !== 'N/A' ? teamAbbreviationToColor[teamAbbreviation] : 'gold';
          return {
            ...playerAward,
            player_picture: picturesMap[playerNameLower], // Add player picture
            team_color: teamColor, // Add team color or default
          };
        });

        setPlayerAwards(mergedPlayerAwards);
        setTeamAwards(teamAwardsData);
        setPlayerPictures(picturesMap);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching awards or pictures:', err);
        setError('Failed to load awards. Please try again later.');
        setLoading(false);
      }
    };

    fetchAwardsAndPictures();
  }, []);

  return (
    <ScrollContainer>
      {loading && <LoadingMessage>Loading awards...</LoadingMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {/* Render Player Awards */}
      {playerAwards.length > 0 ? (
        playerAwards.map(award => {
          const playerNameLower = award.player_name.toLowerCase();
          const playerPicture = award.player_picture;
          const teamAbbreviation = award.team !== 'N/A' ? award.team : null; // Handle 'N/A' team
          const teamColor = teamAbbreviation ? teamAbbreviationToColor[teamAbbreviation] : 'gold';

          return (
            <ScrollSection key={`player-award-${award.id}`}>
              <AwardSection
                award={{
                  ...award,
                  player_picture: playerPicture,
                  team_color: teamColor, // Pass team color
                }}
                type="player"
              />
            </ScrollSection>
          );
        })
      ) : (
        !loading && <LoadingMessage>No player awards available.</LoadingMessage>
      )}

      {/* Render Team Awards */}
      {teamAwards.length > 0 ? (
        teamAwards.map(award => {
          const teamAbbreviation = award.team_name; // Directly use the abbreviation
          const teamColor = teamAbbreviation ? teamAbbreviationToColor[teamAbbreviation] : '#2a2a2a';

          return (
            <ScrollSection key={`team-award-${award.id}`}>
              <AwardSection
                award={{
                  ...award,
                  team_color: teamColor, // Pass team color
                }}
                type="team"
              />
            </ScrollSection>
          );
        })
      ) : (
        !loading && <LoadingMessage>No team awards available.</LoadingMessage>
      )}
    </ScrollContainer>
  );
};

export default AwardsPage;
