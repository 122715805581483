import React, { useState, useEffect } from 'react';
import './ArbitrageCalculator.css';

function ArbitrageCalculator() {
    const [numberOfBets, setNumberOfBets] = useState(2);
    const [bet1, setBet1] = useState(100);
    const [lines, setLines] = useState(['-110', '-110']);
    const [results, setResults] = useState(null);
    const [error, setError] = useState('');

    const handleNumberOfBetsChange = (e) => {
        const numBets = parseInt(e.target.value);
        setNumberOfBets(numBets);
        const newLines = Array(numBets).fill('').map((line, idx) => lines[idx] || '');
        setLines(newLines);
    };

    const handleLineChange = (index, value) => {
        const newLines = [...lines];
        newLines[index] = value;
        setLines(newLines);
    };

    const convertToAmericanOdds = (decimalOdds) => {
        if (decimalOdds < 2) {
            return -100 / (decimalOdds - 1);
        } else {
            return (decimalOdds - 1) * 100;
        }
    };

    const calculateArbitrage = () => {
        if (lines.some(line => line === '')) {
            setError('Please fill in all the odds fields.');
            return;
        }

        const bet1Amount = parseFloat(bet1);
        const decimalOdds = lines.map(line => {
            const lineValue = parseFloat(line);
            if (lineValue < 100) {
                const americanOdds = convertToAmericanOdds(lineValue);
                return americanOdds > 0 ? (americanOdds / 100) + 1 : (100 / -americanOdds) + 1;
            } else {
                return lineValue > 0 ? (lineValue / 100) + 1 : (100 / -lineValue) + 1;
            }
        });

        const totalOddsInverseSum = decimalOdds.reduce((sum, odds) => sum + (1 / odds), 0);
        const totalBet = bet1Amount / (1 / decimalOdds[0] / totalOddsInverseSum);

        const arbBets = decimalOdds.map((odds, idx) => {
            return (bet1Amount * decimalOdds[0] / odds).toFixed(2);
        });

        const profit = (bet1Amount * decimalOdds[0]) - totalBet;
        const profitPercent = (profit / totalBet) * 100;

        setResults({
            arbBets,
            totalBet: totalBet.toFixed(2),
            profit: profit.toFixed(2),
            profitPercent: profitPercent.toFixed(2)
        });

        setError('');
    };

    return (
        <div className="calculator-container">
            <div className="info-container">
                <p>Use this arb calculator to ensure your bets are profitable. Savvy sports bettors leverage hedging and arbitrage to lock in guaranteed profits regardless of the sporting event outcome. Hedging your bets allows you to cover every potential outcome and assure a profit by capitalizing on line changes. Arbitrage involves exploiting discrepancies in odds offered by different sportsbooks, enabling you to cover all outcomes and secure a risk-free profit.</p>
                <p>Calculating the exact stake for each outcome can be challenging. This arbitrage calculator simplifies the process, saving you from the trial and error of manual calculations. Enter your original bet's odds and stake, then the odds of the alternative outcome, and the calculator will determine the ideal stake to guarantee maximum profit.</p>
                <p>Whether you're using American or decimal odds, this tool accommodates both, ensuring precise calculations for your arbitrage and hedging strategies. This makes the betting process quick and efficient, allowing you to focus on finding the best opportunities for risk-free profits.</p>
            </div>
            <div className="form-container">
                <h2>Arbitrage Calculator</h2>
                <div className="form-group">
                    <label htmlFor="numberOfBets">Number of Bets</label>
                    <select
                        id="numberOfBets"
                        value={numberOfBets}
                        onChange={handleNumberOfBetsChange}
                    >
                        {[...Array(12).keys()].map(i => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="bet1">Bet #1</label>
                    <input
                        id="bet1"
                        type="number"
                        value={bet1}
                        onChange={e => setBet1(e.target.value)}
                    />
                </div>
                {lines.map((line, idx) => (
                    <div key={idx} className="form-group">
                        <label htmlFor={`line${idx}`}>Line #{idx + 1}</label>
                        <input
                            id={`line${idx}`}
                            type="number"
                            value={line}
                            onChange={e => handleLineChange(idx, e.target.value)}
                        />
                    </div>
                ))}
                {error && <div className="error">{error}</div>}
                <button onClick={calculateArbitrage}>Calculate</button>
                {results && (
                    <div className="result">
                        {results.arbBets.map((arbBet, idx) => (
                            <p key={idx}>Bet #{idx + 1}: ${arbBet}</p>
                        ))}
                        <p>Total Bet: ${results.totalBet}</p>
                        <p>$ Profit: ${results.profit}</p>
                        <p>% Profit: {results.profitPercent}%</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ArbitrageCalculator;
