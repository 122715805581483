import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './ExitVelocityTracker.css';
import axiosInstance from './axiosInstance';  // Adjust the path as necessary
import debounce from 'lodash/debounce';

const teamNameToLinkDict = {
    "ARI": "https://www.mlbstatic.com/team-logos/109.svg",
    "ATL": "https://www.mlbstatic.com/team-logos/144.svg",
    "BOS": "https://www.mlbstatic.com/team-logos/111.svg",
    "BAL": "https://www.mlbstatic.com/team-logos/110.svg",
    "CHC": "https://www.mlbstatic.com/team-logos/112.svg",
    "CIN": "https://www.mlbstatic.com/team-logos/113.svg",
    "CLE": "https://www.mlbstatic.com/team-logos/114.svg",
    "COL": "https://www.mlbstatic.com/team-logos/115.svg",
    "CWS": "https://www.mlbstatic.com/team-logos/145.svg",
    "DET": "https://www.mlbstatic.com/team-logos/116.svg",
    "HOU": "https://www.mlbstatic.com/team-logos/117.svg",
    "KC": "https://www.mlbstatic.com/team-logos/118.svg",
    "LAA": "https://www.mlbstatic.com/team-logos/108.svg",
    "LAD": "https://www.mlbstatic.com/team-logos/119.svg",
    "MIA": "https://www.mlbstatic.com/team-logos/146.svg",
    "MIL": "https://www.mlbstatic.com/team-logos/158.svg",
    "MIN": "https://www.mlbstatic.com/team-logos/142.svg",
    "NYM": "https://www.mlbstatic.com/team-logos/121.svg",
    "NYY": "https://www.mlbstatic.com/team-logos/147.svg",
    "OAK": "https://www.mlbstatic.com/team-logos/133.svg",
    "PHI": "https://www.mlbstatic.com/team-logos/143.svg",
    "PIT": "https://www.mlbstatic.com/team-logos/134.svg",
    "SD": "https://www.mlbstatic.com/team-logos/135.svg",
    "SEA": "https://www.mlbstatic.com/team-logos/136.svg",
    "SF": "https://www.mlbstatic.com/team-logos/137.svg",
    "STL": "https://www.mlbstatic.com/team-logos/138.svg",
    "TB": "https://www.mlbstatic.com/team-logos/139.svg",
    "TEX": "https://www.mlbstatic.com/team-logos/140.svg",
    "TOR": "https://www.mlbstatic.com/team-logos/141.svg",
    "WAS": "https://www.mlbstatic.com/team-logos/120.svg"
};

const ExitVelocity = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [handFilter, setHandFilter] = useState(['L', 'R']);
    const [teamFilter, setTeamFilter] = useState('');
    const [isNightGameFilter, setIsNightGameFilter] = useState('');
    const [isHomeFilter, setIsHomeFilter] = useState('');
    const [expandedRows, setExpandedRows] = useState({});
    const [atBatsData, setAtBatsData] = useState({});
    const [atBatsLoading, setAtBatsLoading] = useState(false);
    const [sortField, setSortField] = useState('num_100mph_ev');
    const [sortDirection, setSortDirection] = useState('desc');
    const [dateRange, setDateRange] = useState([
        new Date('2024-03-28').getTime(),
        new Date().getTime() - 24 * 60 * 60 * 1000 // one day before current day
    ]);
    const [currentSliderValue, setCurrentSliderValue] = useState(dateRange);
    const [pitcherFilter, setPitcherFilter] = useState('');
    const [pitchers, setPitchers] = useState([]);
    const lastElementRef = useRef(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = useCallback(async () => {
        setLoading(true);  // Ensure loading state is set to true before fetching data
        try {
            const response = await axios.get('https://zeusanalytics.org/api/exit-velocity/', {
                params: {
                    page: page,
                    hand: handFilter.join(','),
                    team: teamFilter,
                    pitcher: pitcherFilter,
                    is_night_game: isNightGameFilter,
                    is_home: isHomeFilter,
                    sort: sortField,
                    direction: sortDirection,
                    start_date: new Date(dateRange[0]).toISOString().split('T')[0],
                    end_date: new Date(dateRange[1]).toISOString().split('T')[0]
                }
            });
            if (response.data.results) {
                setData(prevData => [...prevData, ...response.data.results]);
                setHasMore(response.data.next !== null);
                setLoading(false);  // Set loading to false after data is fetched
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                window.location.href = '/login';
            } else {
                console.error('Error fetching data:', error);
                setLoading(false);  // Ensure loading is set to false in case of error
            }
        }
    }, [page, handFilter, teamFilter, pitcherFilter, isNightGameFilter, isHomeFilter, sortField, sortDirection, dateRange]);

    const fetchPitchers = useCallback(async () => {
        try {
            const response = await axios.get('https://zeusanalytics.org/api/pitchers/');
            setPitchers(response.data);
        } catch (error) {
            console.error('Error fetching pitchers:', error);
        }
    }, []);

    useEffect(() => {
        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);  // Set the authenticated state
                    if (isSubscribed) {
                        fetchData();
                        fetchPitchers();
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, [fetchData, fetchPitchers]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [page, handFilter, teamFilter, pitcherFilter, isNightGameFilter, isHomeFilter, sortField, sortDirection, dateRange, fetchData, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            fetchPitchers();
        }
    }, [fetchPitchers, isAuthenticated]);

    const loadMore = useCallback(() => {
        if (hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    const handleHandFilterChange = (hand) => {
        setHandFilter(prevHandFilter => {
            const newHandFilter = prevHandFilter.includes(hand)
                ? prevHandFilter.filter(h => h !== hand)
                : [...prevHandFilter, hand];
            setPage(1);
            setData([]);
            setAtBatsData({});
            return newHandFilter;
        });
    };

    const handleTeamFilterChange = (event) => {
        setTeamFilter(event.target.value);
        setPage(1);
        setData([]);
        setAtBatsData({});
    };

    const handlePitcherFilterChange = (event) => {
        setPitcherFilter(event.target.value);
        setPage(1);
        setData([]);
        setAtBatsData({});
    };

    const handleIsNightGameFilterChange = (value) => {
        setIsNightGameFilter(prevValue => prevValue === value ? '' : value);
        setPage(1);
        setData([]);
        setAtBatsData({});
    };

    const handleIsHomeFilterChange = (value) => {
        setIsHomeFilter(prevValue => prevValue === value ? '' : value);
        setPage(1);
        setData([]);
        setAtBatsData({});
    };

    const debouncedHandleDateRangeChange = useCallback(
        debounce((values) => {
            setDateRange(values);
            setPage(1);
            setData([]);
            setAtBatsData({});
        }, 500), []
    );

    const fetchAtBatsData = async (batter, dateRange, pageNum = 1) => {
        try {
            setAtBatsLoading(true);
            const response = await axiosInstance.post(`/api/batter-at-bats/`, {
                batter_name: batter,
                hand: handFilter.join(','),
                pitcher: pitcherFilter,
                is_night_game: isNightGameFilter,
                is_home: isHomeFilter,
                page: pageNum,
                start_date: new Date(dateRange[0]).toISOString().split('T')[0],
                end_date: new Date(dateRange[1]).toISOString().split('T')[0]
            });
    
            const newAtBatsData = {
                ...atBatsData,
                [batter]: {
                    data: pageNum === 1 ? response.data.results : [...(atBatsData[batter]?.data || []), ...response.data.results],
                    current_page: response.data.current_page,
                    total_pages: response.data.total_pages
                }
            };
    
            setAtBatsData(newAtBatsData);
            setAtBatsLoading(false);
            console.log(newAtBatsData);
            console.log(new Date(dateRange[1]).toISOString().split('T')[0]);
        } catch (error) {
            console.error('Error fetching at bats data:', error);
            setAtBatsLoading(false);
        }
    };
    
    const toggleRow = (batter) => {
        setExpandedRows(prevExpandedRows => {
            const newExpandedRows = { ...prevExpandedRows, [batter]: !prevExpandedRows[batter] };
            if (!prevExpandedRows[batter]) {
                fetchAtBatsData(batter, dateRange, 1);
            }
            return newExpandedRows;
        });
    };
    

    const handleSort = (field) => {
        const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
        setPage(1);
        setData([]);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                loadMore();
            }
        }, { threshold: 1.0 });

        const currentElement = lastElementRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [loading, hasMore, loadMore]);

    const getExitVelocityColor = (exitVelocity) => {
        if (exitVelocity <= 95) {
            return 'transparent'; // No color for exit velocities 95 or below
        }

        const maxExitVelocity = 121.5;
        const minExitVelocity = 95;
        const ratio = (exitVelocity - minExitVelocity) / (maxExitVelocity - minExitVelocity);
        const red = Math.max(0, Math.min(255, Math.round((1 - ratio) * 255)));
        return `rgba(255, 0, 0, ${0.1 + ratio * 0.5})`; // Adjusting alpha for better visibility
    };

    if (isAuthenticated === null) {
        return <div>Loading...</div>;  // Display loading until the authentication status is determined
    }

    return (
        <div className="exit-velocity">
            <h1>MLB Exit Velocity Tracker 2024</h1>
            <div className="filters-container">
                <h2>Filters</h2>
                <div className="filters">
                    <div className="filter-group">
                        <div
                            className={`filter-box ${handFilter.includes('L') ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('L')}
                        >
                            Left-handed Pitchers
                        </div>
                        <div
                            className={`filter-box ${handFilter.includes('R') ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('R')}
                        >
                            Right-handed Pitchers
                        </div>
                    </div>
                    <div className="filter-group">
                        <div className="team-filter">
                            <label>
                                Team:
                                <select value={teamFilter} onChange={handleTeamFilterChange}>
                                    <option value="">All Teams</option>
                                    {Object.keys(teamNameToLinkDict).map(team => (
                                        <option key={team} value={team}>{team}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="filter-group">
                        <div className="pitcher-filter">
                            <label>
                                Pitcher:
                                <select value={pitcherFilter} onChange={handlePitcherFilterChange}>
                                    <option value="">All Pitchers</option>
                                    {pitchers.map(pitcher => (
                                        <option key={pitcher} value={pitcher}>{pitcher}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="filter-group">
                        <div
                            className={`filter-box ${isNightGameFilter === 'true' ? 'active' : ''}`}
                            onClick={() => handleIsNightGameFilterChange('true')}
                        >
                            Night Game
                        </div>
                        <div
                            className={`filter-box ${isNightGameFilter === 'false' ? 'active' : ''}`}
                            onClick={() => handleIsNightGameFilterChange('false')}
                        >
                            Day Game
                        </div>
                    </div>
                    <div className="filter-group">
                        <div
                            className={`filter-box ${isHomeFilter === 'true' ? 'active' : ''}`}
                            onClick={() => handleIsHomeFilterChange('true')}
                        >
                            Home Game
                        </div>
                        <div
                            className={`filter-box ${isHomeFilter === 'false' ? 'active' : ''}`}
                            onClick={() => handleIsHomeFilterChange('false')}
                        >
                            Away Game
                        </div>
                    </div>
                </div>
                <div className="date-slider-container">
                    <Slider
                        range
                        allowCross={false}
                        value={currentSliderValue}
                        min={new Date('2024-03-28').getTime()}
                        max={new Date().getTime() - 24 * 60 * 60 * 1000}
                        onChange={(values) => setCurrentSliderValue(values)}
                        onAfterChange={(values) => debouncedHandleDateRangeChange(values)}
                    />
                    <div className="date-slider-label">
                        <span>{new Date(currentSliderValue[0]).toLocaleDateString()}</span>
                        <span>{new Date(currentSliderValue[1]).toLocaleDateString()}</span>
                    </div>
                </div>
            </div>

            <div className="table-container">
                <table className="modern-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('batter')}>Batter {sortField === 'batter' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('batter_team')}>Team {sortField === 'batter_team' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('num_100mph_ev')}># of 100mph+ EV {sortField === 'num_100mph_ev' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('avg_exit_velocity')}>Average EV {sortField === 'avg_exit_velocity' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th>At Bat Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr className="clickable-row" onClick={() => toggleRow(row.batter)}>
                                    <td>
                                        <img src={row.player_picture} alt={row.batter} className="player-picture" /> {row.batter}
                                    </td>
                                    <td>
                                        <img src={row.team_logo} alt={row.batter_team} className="team-logo-ev" /> {row.batter_team}
                                    </td>
                                    <td>{row.num_100mph_ev}</td>
                                    <td>{row.avg_exit_velocity !== null ? row.avg_exit_velocity.toFixed(1) : 'N/A'}</td>
                                    <td className="expand-text">
                                        {expandedRows[row.batter] ? "collapse ABs ↑" : "expand ABs ↓"}
                                    </td>
                                </tr>
                                {expandedRows[row.batter] && (
                                    <tr>
                                        <td colSpan="5">
                                            <div className="at-bats-table-container">
                                                <h2>{row.batter} At Bats for 2024</h2>
                                                <div className="table-container">
                                                    <table className="at-bats-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Batter Team</th>
                                                                <th>Batter Name</th>
                                                                <th>Batter Hand</th>
                                                                <th>Exit Velocity</th>
                                                                <th>Launch Angle</th>
                                                                <th>Hit Distance</th>
                                                                <th>Result</th>
                                                                <th>Pitcher Team</th>
                                                                <th>Pitcher Name</th>
                                                                <th>Pitcher Hand</th>
                                                                <th>Pitch Type</th>
                                                                <th>Night Game</th>
                                                                <th>Home Game</th>
                                                                <th>Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {atBatsData[row.batter]?.data.map((atBat, atBatIndex) => (
                                                                <tr key={atBatIndex}>
                                                                    <td><img src={teamNameToLinkDict[atBat.batter_team]} alt={atBat.batter_team} className="team-logo-ev" /> {atBat.batter_team}</td>
                                                                    <td>
                                                                        <img src={atBat.player_picture} alt={`${atBat.batter}`} className="player-picture" />
                                                                        {atBat.batter}
                                                                    </td>
                                                                    <td>{atBat.batter_hand}</td>
                                                                    <td style={{ backgroundColor: getExitVelocityColor(atBat.exit_velocity) }}>{atBat.exit_velocity}</td>
                                                                    <td>{atBat.launch_angle}</td>
                                                                    <td>{atBat.hit_distance}</td>
                                                                    <td>{atBat.result}</td>
                                                                    <td><img src={teamNameToLinkDict[atBat.pitcher_team]} alt={atBat.pitcher_team} className="team-logo-ev" /> {atBat.pitcher_team}</td>
                                                                    <td>
                                                                        <img src={atBat.pitcher_player_picture} alt={`${atBat.pitcher_name}`} className="player-picture" />
                                                                        {atBat.pitcher_name}
                                                                    </td>
                                                                    <td>{atBat.pitcher_hand}</td>
                                                                    <td>{atBat.pitch_type}</td>
                                                                    <td>{atBat.is_night_game ? 'Yes' : 'No'}</td>
                                                                    <td>{atBat.is_home ? 'Yes' : 'No'}</td>
                                                                    <td>{atBat.date}</td>
                                                                </tr>
                                                            ))}
                                                            {atBatsLoading && (
                                                                <tr>
                                                                    <td colSpan="14">Loading...</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {atBatsData[row.batter]?.current_page < atBatsData[row.batter]?.total_pages && (
                                                    <div className="load-more-container">
                                                        <button onClick={() => fetchAtBatsData(row.batter, dateRange, atBatsData[row.batter]?.current_page + 1)}>Load More</button>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {loading && <div>Loading...</div>}
                <div ref={lastElementRef} />
            </div>
        </div>
    );
};

export default ExitVelocity;