import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ParkFactors.css';

const teamNameToAbbreviationDict = {
    "D-backs": "ARI",
    "Braves": "ATL",
    "Red Sox": "BOS",
    "Orioles": "BAL",
    "Cubs": "CHC",
    "Reds": "CIN",
    "Guardians": "CLE",
    "Rockies": "COL",
    "White Sox": "CWS",
    "Tigers": "DET",
    "Astros": "HOU",
    "Royals": "KC",
    "Angels": "LAA",
    "Dodgers": "LAD",
    "Marlins": "MIA",
    "Brewers": "MIL",
    "Twins": "MIN",
    "Mets": "NYM",
    "Yankees": "NYY",
    "Athletics": "OAK",
    "Phillies": "PHI",
    "Pirates": "PIT",
    "Padres": "SD",
    "Mariners": "SEA",
    "Giants": "SF",
    "Cardinals": "STL",
    "Rays": "TB",
    "Rangers": "TEX",
    "Blue Jays": "TOR",
    "Nationals": "WSH"
};

const teamNameToLinkDict = {
    "ARI": "https://www.mlbstatic.com/team-logos/109.svg",
    "ATL": "https://www.mlbstatic.com/team-logos/144.svg",
    "BOS": "https://www.mlbstatic.com/team-logos/111.svg",
    "BAL": "https://www.mlbstatic.com/team-logos/110.svg",
    "CHC": "https://www.mlbstatic.com/team-logos/112.svg",
    "CIN": "https://www.mlbstatic.com/team-logos/113.svg",
    "CLE": "https://www.mlbstatic.com/team-logos/114.svg",
    "COL": "https://www.mlbstatic.com/team-logos/115.svg",
    "CWS": "https://www.mlbstatic.com/team-logos/145.svg",
    "DET": "https://www.mlbstatic.com/team-logos/116.svg",
    "HOU": "https://www.mlbstatic.com/team-logos/117.svg",
    "KC": "https://www.mlbstatic.com/team-logos/118.svg",
    "LAA": "https://www.mlbstatic.com/team-logos/108.svg",
    "LAD": "https://www.mlbstatic.com/team-logos/119.svg",
    "MIA": "https://www.mlbstatic.com/team-logos/146.svg",
    "MIL": "https://www.mlbstatic.com/team-logos/158.svg",
    "MIN": "https://www.mlbstatic.com/team-logos/142.svg",
    "NYM": "https://www.mlbstatic.com/team-logos/121.svg",
    "NYY": "https://www.mlbstatic.com/team-logos/147.svg",
    "OAK": "https://www.mlbstatic.com/team-logos/133.svg",
    "PHI": "https://www.mlbstatic.com/team-logos/143.svg",
    "PIT": "https://www.mlbstatic.com/team-logos/134.svg",
    "SD": "https://www.mlbstatic.com/team-logos/135.svg",
    "SEA": "https://www.mlbstatic.com/team-logos/136.svg",
    "SF": "https://www.mlbstatic.com/team-logos/137.svg",
    "STL": "https://www.mlbstatic.com/team-logos/138.svg",
    "TB": "https://www.mlbstatic.com/team-logos/139.svg",
    "TEX": "https://www.mlbstatic.com/team-logos/140.svg",
    "TOR": "https://www.mlbstatic.com/team-logos/141.svg",
    "WSH": "https://www.mlbstatic.com/team-logos/120.svg"
};

const ParkFactors = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [handFilter, setHandFilter] = useState(['L', 'R']);
    const [dayNightFilter, setDayNightFilter] = useState('');
    const [sortField, setSortField] = useState('home_runs');
    const [sortDirection, setSortDirection] = useState('desc');
    const [minHR, setMinHR] = useState(0);
    const [maxHR, setMaxHR] = useState(1);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [selectedFactor, setSelectedFactor] = useState('home_runs');

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = async () => {
        setLoading(true);  // Ensure loading state is set to true before fetching data
        try {
            const endpoint = getEndpoint(handFilter, dayNightFilter);
            const response = await axios.get(endpoint, {
                params: {
                    page: page,
                    sort: sortField,
                    direction: sortDirection,
                }
            });
            if (response.data.results) {
                const filteredData = response.data.results.filter(row => teamNameToAbbreviationDict[row.team_name]);
                setData(filteredData);
                setTotalPages(response.data.total_pages);
                const factorValues = filteredData.map(row => row[selectedFactor]);
                setMinHR(Math.min(...factorValues));
                setMaxHR(Math.max(...factorValues));
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getEndpoint = (handFilter, dayNightFilter) => {
        if (handFilter.includes('L') && handFilter.includes('R')) {
            if (dayNightFilter === 'day') {
                return 'https://zeusanalytics.org/api/park-factors-leftright-day/';
            } else if (dayNightFilter === 'night') {
                return 'https://zeusanalytics.org/api/park-factors-leftright-night/';
            } else {
                return 'https://zeusanalytics.org/api/park-factors-all/';
            }
        } else if (handFilter.includes('L')) {
            if (dayNightFilter === 'day') {
                return 'https://zeusanalytics.org/api/park-factors-left-day/';
            } else if (dayNightFilter === 'night') {
                return 'https://zeusanalytics.org/api/park-factors-left-night/';
            } else {
                return 'https://zeusanalytics.org/api/park-factors-left-daynight/';
            }
        } else if (handFilter.includes('R')) {
            if (dayNightFilter === 'day') {
                return 'https://zeusanalytics.org/api/park-factors-right-day/';
            } else if (dayNightFilter === 'night') {
                return 'https://zeusanalytics.org/api/park-factors-right-night/';
            } else {
                return 'https://zeusanalytics.org/api/park-factors-right-daynight/';
            }
        } else {
            return 'https://zeusanalytics.org/api/park-factors-all/';
        }
    };

    useEffect(() => {
        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);  // Set the authenticated state
                    if (isSubscribed) {
                        fetchData();
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [page, handFilter, dayNightFilter, sortField, sortDirection, selectedFactor]);

    const handleHandFilterChange = (hand) => {
        setHandFilter(prevHandFilter => {
            const newHandFilter = prevHandFilter.includes(hand)
                ? prevHandFilter.filter(h => h !== hand)
                : [...prevHandFilter, hand];
            setPage(1);
            setData([]);
            return newHandFilter;
        });
    };

    const handleDayNightFilterChange = (value) => {
        setDayNightFilter(prevValue => prevValue === value ? '' : value);
        setPage(1);
        setData([]);
    };

    const handleSort = (field) => {
        const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
        setPage(1);
        setData([]);
    };

    const handleFactorChange = (event) => {
        setSelectedFactor(event.target.value);
    };

    const calculateStars = (value) => {
        const maxStars = 5;
        const normalizedValue = (value - minHR) / (maxHR - minHR);
        const stars = Math.max(0, Math.min(5, normalizedValue * maxStars)); // Ensure stars is between 0 and 5
        const fullStars = Math.floor(stars);
        const halfStars = stars % 1 >= 0.5 ? 1 : 0;
        const emptyStars = maxStars - fullStars - halfStars;

        return { fullStars, halfStars, emptyStars };
    };

    const renderStars = (fullStars, halfStars, emptyStars) => {
        const stars = [];

        for (let i = 0; i < fullStars; i++) {
            stars.push(<div key={`full-${i}`} className="star full"></div>);
        }

        if (halfStars === 1) {
            stars.push(<div key="half" className="star half"></div>);
        }

        for (let i = 0; i < emptyStars; i++) {
            stars.push(<div key={`empty-${i}`} className="star empty"></div>);
        }

        return stars;
    };

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    return (
        <div className="park-factors">
            <h1>MLB Park Factors 2024</h1>
            <div className="filters-container">
                <h2>Filters</h2>
                <div className="filters">
                    <div className="filter-group">
                        <div
                            className={`filter-box ${handFilter.includes('L') ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('L')}
                        >
                            Left-handed Batters
                        </div>
                        <div
                            className={`filter-box ${handFilter.includes('R') ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('R')}
                        >
                            Right-handed Batters
                        </div>
                    </div>
                    <div className="filter-group">
                        <div
                            className={`filter-box ${dayNightFilter === 'day' ? 'active' : ''}`}
                            onClick={() => handleDayNightFilterChange('day')}
                        >
                            Day Games
                        </div>
                        <div
                            className={`filter-box ${dayNightFilter === 'night' ? 'active' : ''}`}
                            onClick={() => handleDayNightFilterChange('night')}
                        >
                            Night Games
                        </div>
                    </div>
                </div>
                <div className="factor-select">
                    <label htmlFor="factor">Select Factor: </label>
                    <select id="factor" value={selectedFactor} onChange={handleFactorChange}>
                        <option value="home_runs">Home Runs</option>
                        <option value="triples">Triples</option>
                        <option value="doubles">Doubles</option>
                        <option value="singles">Singles</option>
                    </select>
                </div>
            </div>

            <div className="table-container">
                <table className="modern-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('team_name')}>Team {sortField === 'team_name' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort(selectedFactor)}>Factor {sortField === selectedFactor && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => {
                            const { fullStars, halfStars, emptyStars } = calculateStars(row[selectedFactor]);
                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            <img src={teamNameToLinkDict[teamNameToAbbreviationDict[row.team_name]]} alt={row.team_name} className="team-logo" /> {row.team_name}
                                        </td>
                                        <td>
                                            <div className="stars">
                                                {renderStars(fullStars, halfStars, emptyStars)}
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
                {loading && <div>Loading...</div>}
            </div>
        </div>
    );
};

export default ParkFactors;
