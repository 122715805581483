// NBAPlayerStatsTracker.js

import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import './NBAPlayerStatsTracker.css';
import axiosInstance from './axiosInstance'; // Adjust the path as necessary

// NBA Teams Data
const nbaTeams = [
  { abbreviation: 'ATL', name: 'Atlanta Hawks', color: '#E03A3E' },
  { abbreviation: 'BOS', name: 'Boston Celtics', color: '#007A33' },
  { abbreviation: 'BKN', name: 'Brooklyn Nets', color: '#000000' },
  { abbreviation: 'CHA', name: 'Charlotte Hornets', color: '#1D1160' },
  { abbreviation: 'CHI', name: 'Chicago Bulls', color: '#CE1141' },
  { abbreviation: 'CLE', name: 'Cleveland Cavaliers', color: '#6F263D' },
  { abbreviation: 'DAL', name: 'Dallas Mavericks', color: '#00538C' },
  { abbreviation: 'DEN', name: 'Denver Nuggets', color: '#0E2240' },
  { abbreviation: 'DET', name: 'Detroit Pistons', color: '#C8102E' },
  { abbreviation: 'GSW', name: 'Golden State Warriors', color: '#1D428A' },
  { abbreviation: 'HOU', name: 'Houston Rockets', color: '#CE1141' },
  { abbreviation: 'IND', name: 'Indiana Pacers', color: '#002D62' },
  { abbreviation: 'LAC', name: 'LA Clippers', color: '#C8102E' },
  { abbreviation: 'LAL', name: 'Los Angeles Lakers', color: '#552583' },
  { abbreviation: 'MEM', name: 'Memphis Grizzlies', color: '#5D76A9' },
  { abbreviation: 'MIA', name: 'Miami Heat', color: '#98002E' },
  { abbreviation: 'MIL', name: 'Milwaukee Bucks', color: '#00471B' },
  { abbreviation: 'MIN', name: 'Minnesota Timberwolves', color: '#0C2340' },
  { abbreviation: 'NOP', name: 'New Orleans Pelicans', color: '#0C2340' },
  { abbreviation: 'NYK', name: 'New York Knicks', color: '#006BB6' },
  { abbreviation: 'OKC', name: 'Oklahoma City Thunder', color: '#007AC1' },
  { abbreviation: 'ORL', name: 'Orlando Magic', color: '#0077C0' },
  { abbreviation: 'PHI', name: 'Philadelphia 76ers', color: '#006BB6' },
  { abbreviation: 'PHX', name: 'Phoenix Suns', color: '#1D1160' },
  { abbreviation: 'POR', name: 'Portland Trail Blazers', color: '#E03A3E' },
  { abbreviation: 'SAC', name: 'Sacramento Kings', color: '#5A2D81' },
  { abbreviation: 'SAS', name: 'San Antonio Spurs', color: '#C4CED4' },
  { abbreviation: 'TOR', name: 'Toronto Raptors', color: '#CE1141' },
  { abbreviation: 'UTA', name: 'Utah Jazz', color: '#002B5C' },
  { abbreviation: 'WAS', name: 'Washington Wizards', color: '#002B5C' },
];

// Seasons Data
const seasons = ['2021-2022', '2022-2023', '2023-2024', '2024-2025'];

// Mapping: Team Abbreviation to Color
const teamAbbreviationToColor = nbaTeams.reduce((acc, team) => {
  acc[team.abbreviation] = team.color;
  return acc;
}, {});

// Mapping: Team Abbreviation to Full Name
const teamAbbreviationToName = nbaTeams.reduce((acc, team) => {
  acc[team.abbreviation] = team.name;
  return acc;
}, {});

// Mapping: Team Abbreviation to Logo URL
const teamAbbreviationToLogoUrl = {
  'BOS': 'https://cdn.nba.com/logos/nba/1610612738/primary/L/logo.svg',
  'BKN': 'https://cdn.nba.com/logos/nba/1610612751/primary/L/logo.svg',
  'NYK': 'https://cdn.nba.com/logos/nba/1610612752/primary/L/logo.svg',
  'PHI': 'https://cdn.nba.com/logos/nba/1610612755/primary/L/logo.svg',
  'TOR': 'https://cdn.nba.com/logos/nba/1610612761/primary/L/logo.svg',
  'CHI': 'https://cdn.nba.com/logos/nba/1610612741/primary/L/logo.svg',
  'CLE': 'https://cdn.nba.com/logos/nba/1610612739/primary/L/logo.svg',
  'DET': 'https://cdn.nba.com/logos/nba/1610612765/primary/L/logo.svg',
  'IND': 'https://cdn.nba.com/logos/nba/1610612754/primary/L/logo.svg',
  'MIL': 'https://cdn.nba.com/logos/nba/1610612749/primary/L/logo.svg',
  'ATL': 'https://cdn.nba.com/logos/nba/1610612737/primary/L/logo.svg',
  'CHA': 'https://cdn.nba.com/logos/nba/1610612766/primary/L/logo.svg',
  'MIA': 'https://cdn.nba.com/logos/nba/1610612748/primary/L/logo.svg',
  'ORL': 'https://cdn.nba.com/logos/nba/1610612753/primary/L/logo.svg',
  'WAS': 'https://cdn.nba.com/logos/nba/1610612764/primary/L/logo.svg',
  'DEN': 'https://cdn.nba.com/logos/nba/1610612743/primary/L/logo.svg',
  'MIN': 'https://cdn.nba.com/logos/nba/1610612750/primary/L/logo.svg',
  'OKC': 'https://cdn.nba.com/logos/nba/1610612760/primary/L/logo.svg',
  'POR': 'https://cdn.nba.com/logos/nba/1610612757/primary/L/logo.svg',
  'UTA': 'https://cdn.nba.com/logos/nba/1610612762/primary/L/logo.svg',
  'GSW': 'https://cdn.nba.com/logos/nba/1610612744/primary/L/logo.svg',
  'LAC': 'https://cdn.nba.com/logos/nba/1610612746/primary/L/logo.svg',
  'LAL': 'https://cdn.nba.com/logos/nba/1610612747/primary/L/logo.svg',
  'PHX': 'https://cdn.nba.com/logos/nba/1610612756/primary/L/logo.svg',
  'SAC': 'https://cdn.nba.com/logos/nba/1610612758/primary/L/logo.svg',
  'DAL': 'https://cdn.nba.com/logos/nba/1610612742/primary/L/logo.svg',
  'HOU': 'https://cdn.nba.com/logos/nba/1610612745/primary/L/logo.svg',
  'MEM': 'https://cdn.nba.com/logos/nba/1610612763/primary/L/logo.svg',
  'NOP': 'https://cdn.nba.com/logos/nba/1610612740/primary/L/logo.svg',
  'SAS': 'https://cdn.nba.com/logos/nba/1610612759/primary/L/logo.svg',
};

// Default Profile Picture URL
const defaultProfilePictureUrl = 'https://s3.amazonaws.com/37assets/svn/765-default-avatar.png'; // Replace with your default image URL

const NBAPlayerStatsTracker = () => {
  // State Variables
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [playerFilter, setPlayerFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState([]);
  const [opponentFilter, setOpponentFilter] = useState([]);
  const [seasonFilter, setSeasonFilter] = useState(['2024-2025']); // Default season
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [expandedRows, setExpandedRows] = useState({});
  const [gameLogsData, setGameLogsData] = useState({});
  const [gameLogsLoading, setGameLogsLoading] = useState(false);
  const [sortField, setSortField] = useState('avg_points');
  const [sortDirection, setSortDirection] = useState('desc');
  const lastElementRef = useRef(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // Fetch Player Statistics Data
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page,
        player_name: playerFilter,
        team: teamFilter, // Send as array
        opponent: opponentFilter, // Send as array
        season: seasonFilter, // Send as array
        start_date: startDate,
        end_date: endDate,
        sort: sortField,
        direction: sortDirection,
      };

      // Remove empty parameters
      Object.keys(params).forEach(
        (key) => (params[key] === '' || params[key].length === 0) && delete params[key]
      );

      const response = await axiosInstance.get('/api/nba-gen-player-stats-aggregated/', {
        params,
      });

      if (response.data.results) {
        setData((prevData) =>
          page === 1 ? response.data.results : [...prevData, ...response.data.results]
        );
        setHasMore(response.data.next !== null);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        window.location.href = '/login';
      } else {
        console.error('Error fetching data:', error);
      }
    } finally {
      setLoading(false);
    }
  }, [
    page,
    playerFilter,
    teamFilter,
    opponentFilter,
    seasonFilter,
    startDate,
    endDate,
    sortField,
    sortDirection,
  ]);

  // Debounced Fetch Data to Optimize Performance
  const debouncedFetchData = useCallback(
    debounce(() => {
      fetchData();
    }, 300),
    [fetchData]
  );

  // Fetch Game Logs Data for a Specific Player
  const fetchGameLogsData = async (playerName, pageNum = 1) => {
    setGameLogsLoading(true);
    try {
      const params = {
        player_name: playerName,
        season: seasonFilter, // Send as array
        opponent: opponentFilter, // Send as array
        start_date: startDate,
        end_date: endDate,
        page: pageNum,
      };

      // Remove empty parameters
      Object.keys(params).forEach(
        (key) => (params[key] === '' || params[key].length === 0) && delete params[key]
      );

      const response = await axiosInstance.get('/api/nba-gen-player-stats/', {
        params,
      });

      setGameLogsData((prevData) => ({
        ...prevData,
        [playerName]: {
          data:
            pageNum === 1
              ? response.data.results
              : [...(prevData[playerName]?.data || []), ...response.data.results],
          current_page: response.data.current_page,
          total_pages: response.data.total_pages,
        },
      }));
    } catch (error) {
      console.error('Error fetching game logs data:', error);
    } finally {
      setGameLogsLoading(false);
    }
  };

  // Check Subscription Status
  const checkSubscriptionStatus = async (token) => {
    try {
      const response = await axios.get('/api/check-subscription/', {
        params: { token },
      });
      const subscriptions = response.data;
      const currentTime = new Date().toISOString();

      const isSubscribed = subscriptions.some(
        (subscription) =>
          subscription.price.product.capper.id === 320906 &&
          subscription.paid_until > currentTime
      );

      return isSubscribed;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false;
    }
  };

  // Initial Authentication and Data Fetching
  useEffect(() => {
    axios
      .get('/profile/')
      .then((response) => {
        const { access_token } = response.data;
        checkSubscriptionStatus(access_token).then((isSubscribed) => {
          setIsAuthenticated(isSubscribed);
          if (isSubscribed) {
            fetchData();
          } else {
            window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching profile data:', error);
        window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch Data When Filters or Sorting Change
  useEffect(() => {
    if (isAuthenticated) {
      debouncedFetchData();
    }
  }, [
    page,
    playerFilter,
    teamFilter,
    opponentFilter,
    seasonFilter,
    startDate,
    endDate,
    sortField,
    sortDirection,
    debouncedFetchData,
    isAuthenticated,
  ]);

  // Load More Data (Pagination)
  const loadMore = useCallback(() => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasMore, loading]);

  // Intersection Observer for Infinite Scrolling
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current);
      }
    };
  }, [loadMore, hasMore, loading]);

  // Handlers for Filters
  const handlePlayerFilterChange = (e) => {
    setPlayerFilter(e.target.value);
    setPage(1);
    setData([]);
    setGameLogsData({});
  };

  const handleTeamFilterChange = (teamAbbr) => {
    setTeamFilter((prev) => {
      if (prev.includes(teamAbbr)) {
        return prev.filter((team) => team !== teamAbbr);
      } else {
        return [...prev, teamAbbr];
      }
    });
    setPage(1);
    setData([]);
    setGameLogsData({});
  };

  const handleOpponentFilterChange = (teamAbbr) => {
    setOpponentFilter((prev) => {
      if (prev.includes(teamAbbr)) {
        return prev.filter((team) => team !== teamAbbr);
      } else {
        return [...prev, teamAbbr];
      }
    });
    setPage(1);
    setData([]);
    setGameLogsData({});
  };

  const handleSeasonFilterChange = (season) => {
    setSeasonFilter((prev) => {
      if (prev.includes(season)) {
        return prev.filter((s) => s !== season);
      } else {
        return [...prev, season];
      }
    });
    setPage(1);
    setData([]);
    setGameLogsData({});
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setPage(1);
    setData([]);
    setGameLogsData({});
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setPage(1);
    setData([]);
    setGameLogsData({});
  };

  // Handler for Sorting
  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
    setPage(1);
    setData([]);
  };

  // Toggle Expanded Rows for Game Logs
  const toggleRow = (playerName) => {
    setExpandedRows((prev) => {
      const newExpandedRows = { ...prev, [playerName]: !prev[playerName] };
      if (!prev[playerName]) {
        fetchGameLogsData(playerName);
      }
      return newExpandedRows;
    });
  };

  // Format Date Function
  const formatDate = (dateString) => {
    // Split the date string to prevent timezone shifts by using YYYY/MM/DD format
    const [year, month, day] = dateString.split('-');
    const date = new Date(`${year}/${month}/${day}`);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  // Loading State While Checking Authentication
  if (isAuthenticated === null) {
    return (
      <div className="nps-loading-container">
        <div className="nps-spinner"></div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="nps-player-stats">
      <h1 className="nps-title">NBA Player Stats Tracker</h1>
      {/* Filter Bar */}
      <div className="nps-filter-container">
        {/* Player Search */}
        <input
          type="text"
          placeholder="Search Player"
          value={playerFilter}
          onChange={handlePlayerFilterChange}
          className="nps-search-input"
        />

        {/* Teams Dropdown */}
        <div className="nps-dropdown">
          <button className="nps-dropbtn">Teams &#9662;</button>
          <div className="nps-dropdown-content">
            <div className="nps-dropdown-header">Select Teams</div>
            {nbaTeams.map((team) => (
              <label key={team.abbreviation} className="nps-dropdown-item">
                <input
                  type="checkbox"
                  checked={teamFilter.includes(team.abbreviation)}
                  onChange={() => handleTeamFilterChange(team.abbreviation)}
                />
                <span className="nps-team-name">{team.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Opponents Dropdown */}
        <div className="nps-dropdown">
          <button className="nps-dropbtn">Opponents &#9662;</button>
          <div className="nps-dropdown-content">
            <div className="nps-dropdown-header">Select Opponents</div>
            {nbaTeams.map((team) => (
              <label key={team.abbreviation} className="nps-dropdown-item">
                <input
                  type="checkbox"
                  checked={opponentFilter.includes(team.abbreviation)}
                  onChange={() => handleOpponentFilterChange(team.abbreviation)}
                />
                <span className="nps-team-name">{team.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Seasons Dropdown */}
        <div className="nps-dropdown">
          <button className="nps-dropbtn">Seasons &#9662;</button>
          <div className="nps-dropdown-content">
            <div className="nps-dropdown-header">Select Seasons</div>
            {seasons.map((season) => (
              <label key={season} className="nps-dropdown-item">
                <input
                  type="checkbox"
                  checked={seasonFilter.includes(season)}
                  onChange={() => handleSeasonFilterChange(season)}
                />
                {season}
              </label>
            ))}
          </div>
        </div>

        {/* Date Range Filter */}
        <div className="nps-date-filter">
          <label className="nps-date-label">
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="nps-date-input"
            />
          </label>
          <label className="nps-date-label">
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="nps-date-input"
            />
          </label>
        </div>
      </div>

      {/* Table */}
      <div className="nps-table-container">
        <table className="nps-modern-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('player_name')}>
                Player {sortField === 'player_name' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('team')}>
                Team {sortField === 'team' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_points')}>
                Pts {sortField === 'avg_points' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_reb')}>
                Rebs {sortField === 'avg_reb' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_ast')}>
                Asts {sortField === 'avg_ast' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_stl')}>
                Stls {sortField === 'avg_stl' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_blk')}>
                Blks {sortField === 'avg_blk' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_tov')}>
                Tovs {sortField === 'avg_tov' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_fgm')}>
                FGM {sortField === 'avg_fgm' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_fga')}>
                FGA {sortField === 'avg_fga' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_threepm')}>
                ThreePM {sortField === 'avg_threepm' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_threepa')}>
                ThreePA {sortField === 'avg_threepa' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_ftm')}>
                FTM {sortField === 'avg_ftm' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th onClick={() => handleSort('avg_fta')}>
                FTA {sortField === 'avg_fta' && (sortDirection === 'asc' ? '↑' : '↓')}
              </th>
              <th>Game Logs</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <React.Fragment key={index}>
                  <tr className="nps-clickable-row" onClick={() => toggleRow(row.player_name)}>
                    {/* Player Name and Picture */}
                    <td className="nps-player-cell">
                      <div className="nps-player-content">
                        <img
                          src={row.profile_picture || defaultProfilePictureUrl}
                          alt={row.player_name}
                          className="nps-player-picture"
                        />
                        {row.player_name}
                      </div>
                    </td>

                    {/* Team Name and Logo */}
                    <td
                      className="nps-team-cell"
                      style={{
                        backgroundColor: teamAbbreviationToColor[row.team],
                        color: '#ffffff',
                      }}
                      title={teamAbbreviationToName[row.team] || row.team}
                    >
                      <div className="nps-team-content">
                        <img
                          src={teamAbbreviationToLogoUrl[row.team] || defaultProfilePictureUrl}
                          alt={teamAbbreviationToName[row.team] || row.team}
                          className="nps-team-logo"
                        />
                        {teamAbbreviationToName[row.team] || row.team}
                      </div>
                    </td>

                    {/* Player Statistics */}
                    <td>{row.avg_points.toFixed(1)}</td>
                    <td>{row.avg_reb.toFixed(1)}</td>
                    <td>{row.avg_ast.toFixed(1)}</td>
                    <td>{row.avg_stl.toFixed(1)}</td>
                    <td>{row.avg_blk.toFixed(1)}</td>
                    <td>{row.avg_tov.toFixed(1)}</td>
                    <td>{row.avg_fgm.toFixed(1)}</td>
                    <td>{row.avg_fga.toFixed(1)}</td>
                    <td>{row.avg_threepm.toFixed(1)}</td>
                    <td>{row.avg_threepa.toFixed(1)}</td>
                    <td>{row.avg_ftm.toFixed(1)}</td>
                    <td>{row.avg_fta.toFixed(1)}</td>

                    {/* Expand/Collapse Game Logs */}
                    <td className="nps-expand-text">
                      {expandedRows[row.player_name] ? 'Collapse Logs ↑' : 'Expand Logs ↓'}
                    </td>
                  </tr>

                  {/* Expanded Row for Game Logs */}
                  {expandedRows[row.player_name] && (
                    <tr>
                      <td colSpan="15">
                        <div className="nps-game-logs-table-container">
                          <h2>{row.player_name} Game Logs</h2>
                          {gameLogsData[row.player_name]?.data.length > 0 ? (
                            <table className="nps-game-logs-table">
                              <thead>
                                <tr>
                                  <th>Opponent</th>
                                  <th>MP</th> {/* New Minutes Played Header */}
                                  <th>Pts</th>
                                  <th>Rebs</th>
                                  <th>Asts</th>
                                  <th>FGs</th>
                                  <th>3P</th>
                                  <th>FTs</th>
                                  <th>Stls</th>
                                  <th>Blks</th>
                                  <th>Tovs</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gameLogsData[row.player_name]?.data.slice().reverse().map((gameLog, idx) => (
                                  <tr key={idx}>
                                    {/* Opponent Name and Logo */}
                                    <td
                                      className="nps-opponent-cell"
                                      style={{
                                        backgroundColor: teamAbbreviationToColor[gameLog.opponent],
                                        color: '#ffffff',
                                      }}
                                      title={
                                        teamAbbreviationToName[gameLog.opponent] ||
                                        gameLog.opponent
                                      }
                                    >
                                      <div className="nps-opponent-content">
                                        <img
                                          src={teamAbbreviationToLogoUrl[gameLog.opponent] || defaultProfilePictureUrl}
                                          alt={teamAbbreviationToName[gameLog.opponent] || gameLog.opponent}
                                          className="nps-team-logo"
                                        />
                                        {teamAbbreviationToName[gameLog.opponent] ||
                                          gameLog.opponent}
                                      </div>
                                    </td>

                                    {/* Minutes Played */}
                                    <td>{gameLog.minutes_played}</td> {/* New Minutes Played Cell */}

                                    {/* Game Statistics */}
                                    <td>{gameLog.points}</td>
                                    <td>{gameLog.reb}</td>
                                    <td>{gameLog.ast}</td>
                                    <td>
                                      {gameLog.fgm}-{gameLog.fga}
                                    </td>
                                    <td>
                                      {gameLog.three_pm}-{gameLog.three_pa}
                                    </td>
                                    <td>
                                      {gameLog.ftm}-{gameLog.fta}
                                    </td>
                                    <td>{gameLog.stl}</td>
                                    <td>{gameLog.blk}</td>
                                    <td>{gameLog.tov}</td>
                                    <td>{formatDate(gameLog.game_date)}</td>
                                  </tr>
                                ))}
                                {gameLogsLoading && (
                                  <tr>
                                    <td colSpan="12" className="nps-loading-cell"> {/* Updated colSpan */}
                                      <div className="nps-spinner-small"></div>
                                      Loading...
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          ) : (
                            !gameLogsLoading && (
                              <div className="nps-no-game-logs">No Game Logs</div>
                            )
                          )}
                          {/* Load More Button for Game Logs */}
                          {gameLogsData[row.player_name]?.current_page <
                            gameLogsData[row.player_name]?.total_pages && (
                            <div className="nps-load-more-container">
                              <button
                                className="nps-load-more-button"
                                onClick={() =>
                                  fetchGameLogsData(
                                    row.player_name,
                                    gameLogsData[row.player_name].current_page + 1
                                  )
                                }
                              >
                                Load More
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              !loading && (
                <tr>
                  <td colSpan="15" className="nps-no-data">
                    No data available for the selected filters.
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        {/* Loading Spinner */}
        {loading && (
          <div className="nps-loading-container">
            <div className="nps-spinner"></div>
            <div>Loading...</div>
          </div>
        )}

        {/* Reference Element for Infinite Scrolling */}
        <div ref={lastElementRef}></div>
      </div>
    </div>
  );
};

export default NBAPlayerStatsTracker;