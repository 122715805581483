import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'nouislider/dist/nouislider.css';
import './DailyLineupStats.css';

const teamNameToLinkDict = {
    "ARI": "https://www.mlbstatic.com/team-logos/109.svg",
    "ATL": "https://www.mlbstatic.com/team-logos/144.svg",
    "BOS": "https://www.mlbstatic.com/team-logos/111.svg",
    "BAL": "https://www.mlbstatic.com/team-logos/110.svg",
    "CHC": "https://www.mlbstatic.com/team-logos/112.svg",
    "CIN": "https://www.mlbstatic.com/team-logos/113.svg",
    "CLE": "https://www.mlbstatic.com/team-logos/114.svg",
    "COL": "https://www.mlbstatic.com/team-logos/115.svg",
    "CWS": "https://www.mlbstatic.com/team-logos/145.svg",
    "DET": "https://www.mlbstatic.com/team-logos/116.svg",
    "HOU": "https://www.mlbstatic.com/team-logos/117.svg",
    "KC": "https://www.mlbstatic.com/team-logos/118.svg",
    "LAA": "https://www.mlbstatic.com/team-logos/108.svg",
    "LAD": "https://www.mlbstatic.com/team-logos/119.svg",
    "MIA": "https://www.mlbstatic.com/team-logos/146.svg",
    "MIL": "https://www.mlbstatic.com/team-logos/158.svg",
    "MIN": "https://www.mlbstatic.com/team-logos/142.svg",
    "NYM": "https://www.mlbstatic.com/team-logos/121.svg",
    "NYY": "https://www.mlbstatic.com/team-logos/147.svg",
    "OAK": "https://www.mlbstatic.com/team-logos/133.svg",
    "PHI": "https://www.mlbstatic.com/team-logos/143.svg",
    "PIT": "https://www.mlbstatic.com/team-logos/134.svg",
    "SD": "https://www.mlbstatic.com/team-logos/135.svg",
    "SEA": "https://www.mlbstatic.com/team-logos/136.svg",
    "SF": "https://www.mlbstatic.com/team-logos/137.svg",
    "STL": "https://www.mlbstatic.com/team-logos/138.svg",
    "TB": "https://www.mlbstatic.com/team-logos/139.svg",
    "TEX": "https://www.mlbstatic.com/team-logos/140.svg",
    "TOR": "https://www.mlbstatic.com/team-logos/141.svg",
    "WSH": "https://www.mlbstatic.com/team-logos/120.svg"
};

const teamNameToAbbreviationDict = {
    "Arizona Diamondbacks": "ARI",
    "Atlanta Braves": "ATL",
    "Boston Red Sox": "BOS",
    "Baltimore Orioles": "BAL",
    "Chicago Cubs": "CHC",
    "Cincinnati Reds": "CIN",
    "Cleveland Guardians": "CLE",
    "Colorado Rockies": "COL",
    "Chicago White Sox": "CWS",
    "Detroit Tigers": "DET",
    "Houston Astros": "HOU",
    "Kansas City Royals": "KC",
    "Los Angeles Angels": "LAA",
    "Los Angeles Dodgers": "LAD",
    "Miami Marlins": "MIA",
    "Milwaukee Brewers": "MIL",
    "Minnesota Twins": "MIN",
    "New York Mets": "NYM",
    "New York Yankees": "NYY",
    "Oakland Athletics": "OAK",
    "Philadelphia Phillies": "PHI",
    "Pittsburgh Pirates": "PIT",
    "San Diego Padres": "SD",
    "Seattle Mariners": "SEA",
    "San Francisco Giants": "SF",
    "St. Louis Cardinals": "STL",
    "Tampa Bay Rays": "TB",
    "Texas Rangers": "TEX",
    "Toronto Blue Jays": "TOR",
    "Washington Nationals": "WSH"
};

const DailyLineupStats = () => {
    const [dailyStats, setDailyStats] = useState([]);
    const [pitchAverages, setPitchAverages] = useState([]);
    const [gameOrder, setGameOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = async () => {
        setLoading(true);  // Ensure loading state is set to true before fetching data
        try {
            const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            const [lineupResponse, pitchAveragesResponse, scheduleResponse] = await Promise.all([
                axios.get('https://zeusanalytics.org/api/daily-lineup-stats/'),
                axios.get('https://zeusanalytics.org/api/pitch-averages/'),
                axios.get(`https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${currentDate}`)
            ]);

            setDailyStats(lineupResponse.data.results);
            setPitchAverages(pitchAveragesResponse.data.results);

            const games = scheduleResponse.data.events;
            const gameOrder = games.map(game => {
                const homeTeam = teamNameToAbbreviationDict[game.competitions[0].competitors.find(comp => comp.homeAway === "home").team.displayName];
                const awayTeam = teamNameToAbbreviationDict[game.competitions[0].competitors.find(comp => comp.homeAway === "away").team.displayName];
                const startTime = new Date(game.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });

                return {
                    awayTeam,
                    homeTeam,
                    date: new Date(game.date),
                    startTime
                };
            });
            setGameOrder(gameOrder);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);  // Set the authenticated state
                    if (isSubscribed) {
                        fetchData();
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    const getTeamPitchAverage = (team, hand) => {
        const suffix = hand === 'left' ? '_left' : '_right';
        const teamAverage = pitchAverages.find(pa => pa.team === team + suffix);
        return teamAverage ? teamAverage.avg_pitches_per_ab : 'N/A';
    };

    const getLeaguePitchAverage = (hand) => {
        const suffix = hand === 'left' ? '_left' : '_right';
        const leagueAverage = pitchAverages.find(pa => pa.team === 'MLB' + suffix);
        return leagueAverage ? leagueAverage.avg_pitches_per_ab : 'N/A';
    };

    const getMatchups = () => {
        const groupedByGame = dailyStats.reduce((acc, stat) => {
            const key = `${stat.batter_team}-${stat.opp_pitcher_team}`;
            const reverseKey = `${stat.opp_pitcher_team}-${stat.batter_team}`;

            if (acc[key]) {
                acc[key].away_batters.push(stat);
                acc[key].home_projected_pitcher = stat.opp_pitcher_name + ` (${stat.opp_pitcher_hand})`;
                acc[key].home_projected_pitcher_hand = stat.opp_pitcher_hand;
            } else if (acc[reverseKey]) {
                acc[reverseKey].home_batters.push(stat);
                acc[reverseKey].away_projected_pitcher = stat.opp_pitcher_name + ` (${stat.opp_pitcher_hand})`;
                acc[reverseKey].away_projected_pitcher_hand = stat.opp_pitcher_hand;
            } else {
                acc[key] = {
                    away_team: stat.batter_team,
                    home_team: stat.opp_pitcher_team,
                    away_projected_pitcher: stat.opp_pitcher_name + ` (${stat.opp_pitcher_hand})`,
                    away_projected_pitcher_hand: stat.opp_pitcher_hand,
                    home_projected_pitcher: stat.opp_pitcher_name + ` (${stat.opp_pitcher_hand})`,
                    home_projected_pitcher_hand: stat.opp_pitcher_hand,
                    away_batters: [],
                    home_batters: []
                };
                acc[key].away_batters.push(stat);
            }
            return acc;
        }, {});

        return Object.values(groupedByGame);
    };

    const matchups = getMatchups().map(game => {
        const gameDetails = gameOrder.find(g => g.awayTeam === game.away_team && g.homeTeam === game.home_team);
        if (!gameDetails) {
            // Swap home and away teams if the gameDetails is not found
            const swappedGameDetails = gameOrder.find(g => g.awayTeam === game.home_team && g.homeTeam === game.away_team);
            if (swappedGameDetails) {
                return {
                    away_team: game.home_team,
                    home_team: game.away_team,
                    away_projected_pitcher: game.home_projected_pitcher,
                    away_projected_pitcher_hand: game.home_projected_pitcher_hand,
                    home_projected_pitcher: game.away_projected_pitcher,
                    home_projected_pitcher_hand: game.away_projected_pitcher_hand,
                    away_batters: game.home_batters,
                    home_batters: game.away_batters,
                    startTime: swappedGameDetails.startTime
                };
            }
        } else {
            return {
                ...game,
                startTime: gameDetails.startTime
            };
        }
        return game;
    }).sort((a, b) => {
        const gameA = gameOrder.find(game => game.awayTeam === a.away_team && game.homeTeam === a.home_team);
        const gameB = gameOrder.find(game => game.awayTeam === b.away_team && game.homeTeam === b.home_team);
        return gameA && gameB ? gameA.date - gameB.date : 0;
    });

    const currentDateFormatted = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    if (isAuthenticated === null) {
        return <div>Loading...</div>;  // Display loading until the authentication status is determined
    }

    return (
        <div className="daily-lineup-stats">
            <h1>Daily Lineup Stats - {currentDateFormatted}</h1>
            {matchups.length === 0 && !loading && <div>No data available.</div>}
            {matchups.map((game, index) => (
                <div className="game" key={index}>
                    <div className="team-header">
                        <div className="team-info">
                            <img src={teamNameToLinkDict[game.away_team]} alt={game.away_team} className="team-logo" />
                            <span>{game.away_team}</span>
                        </div>
                        <span> @ </span>
                        <div className="team-info">
                            <img src={teamNameToLinkDict[game.home_team]} alt={game.home_team} className="team-logo" />
                            <span>{game.home_team}</span>
                        </div>
                        {game.startTime && <span className="game-time">{game.startTime}</span>}
                    </div>
                    <div className="lineup-container">
                        <div className="lineup">
                            <div className="projected-pitcher">
                                <h3>Opposing Starting Pitcher</h3>
                                <div className="projected-pitcher-info">
                                    <img src={teamNameToLinkDict[game.home_team]} alt={game.home_team} className="team-logo" />
                                    <span>{game.home_projected_pitcher}</span>
                                </div>
                            </div>
                            <h2>
                                <img src={teamNameToLinkDict[game.away_team]} alt={game.away_team} className="team-logo" />
                                {game.away_team} Batters
                            </h2>
                            <table className="lineup-table">
                                <thead>
                                    <tr>
                                        <th>Batter</th>
                                        <th>Pitches Per AB</th>
                                        <th className={game.home_projected_pitcher_hand === 'L' ? 'bold' : ''}>Pitches Per AB vs LHP</th>
                                        <th className={game.home_projected_pitcher_hand === 'R' ? 'bold' : ''}>Pitches Per AB vs RHP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {game.away_batters.map((batter, idx) => (
                                        <tr key={idx}>
                                            <td>{batter.batter_name}</td>
                                            <td>{batter.avg_pitches_per_ab}</td>
                                            <td className={game.home_projected_pitcher_hand === 'L' ? 'bold' : ''}>{batter.avg_pitches_per_ab_L}</td>
                                            <td className={game.home_projected_pitcher_hand === 'R' ? 'bold' : ''}>{batter.avg_pitches_per_ab_R}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="2">Team Average vs LHP</td>
                                        <td colSpan="2" className={game.home_projected_pitcher_hand === 'L' ? 'bold' : ''}>{getTeamPitchAverage(game.away_team, 'left')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Team Average vs RHP</td>
                                        <td colSpan="2" className={game.home_projected_pitcher_hand === 'R' ? 'bold' : ''}>{getTeamPitchAverage(game.away_team, 'right')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">League Average vs LHP</td>
                                        <td colSpan="2" className={game.home_projected_pitcher_hand === 'L' ? 'bold' : ''}>{getLeaguePitchAverage('left')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">League Average vs RHP</td>
                                        <td colSpan="2" className={game.home_projected_pitcher_hand === 'R' ? 'bold' : ''}>{getLeaguePitchAverage('right')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="lineup">
                            <div className="projected-pitcher">
                                <h3>Opposing Starting Pitcher</h3>
                                <div className="projected-pitcher-info">
                                    <img src={teamNameToLinkDict[game.away_team]} alt={game.away_team} className="team-logo" />
                                    <span>{game.away_projected_pitcher}</span>
                                </div>
                            </div>
                            <h2>
                                <img src={teamNameToLinkDict[game.home_team]} alt={game.home_team} className="team-logo" />
                                {game.home_team} Batters
                            </h2>
                            <table className="lineup-table">
                                <thead>
                                    <tr>
                                        <th>Batter</th>
                                        <th>Pitches Per AB</th>
                                        <th className={game.away_projected_pitcher_hand === 'L' ? 'bold' : ''}>Pitches Per AB vs LHP</th>
                                        <th className={game.away_projected_pitcher_hand === 'R' ? 'bold' : ''}>Pitches Per AB vs RHP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {game.home_batters.map((batter, idx) => (
                                        <tr key={idx}>
                                            <td>{batter.batter_name}</td>
                                            <td>{batter.avg_pitches_per_ab}</td>
                                            <td className={game.away_projected_pitcher_hand === 'L' ? 'bold' : ''}>{batter.avg_pitches_per_ab_L}</td>
                                            <td className={game.away_projected_pitcher_hand === 'R' ? 'bold' : ''}>{batter.avg_pitches_per_ab_R}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="2">Team Average vs LHP</td>
                                        <td colSpan="2" className={game.away_projected_pitcher_hand === 'L' ? 'bold' : ''}>{getTeamPitchAverage(game.home_team, 'left')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">Team Average vs RHP</td>
                                        <td colSpan="2" className={game.away_projected_pitcher_hand === 'R' ? 'bold' : ''}>{getTeamPitchAverage(game.home_team, 'right')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">League Average vs LHP</td>
                                        <td colSpan="2" className={game.away_projected_pitcher_hand === 'L' ? 'bold' : ''}>{getLeaguePitchAverage('left')}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">League Average vs RHP</td>
                                        <td colSpan="2" className={game.away_projected_pitcher_hand === 'R' ? 'bold' : ''}>{getLeaguePitchAverage('right')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ))}
            {loading && <div>Loading...</div>}
        </div>
    );
};

export default DailyLineupStats;