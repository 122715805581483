import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import './ExitVelocityTracker.css';

const teamNameToLinkDict = {
    "ARI": "https://www.mlbstatic.com/team-logos/109.svg",
    "ATL": "https://www.mlbstatic.com/team-logos/144.svg",
    "BOS": "https://www.mlbstatic.com/team-logos/111.svg",
    "BAL": "https://www.mlbstatic.com/team-logos/110.svg",
    "CHC": "https://www.mlbstatic.com/team-logos/112.svg",
    "CIN": "https://www.mlbstatic.com/team-logos/113.svg",
    "CLE": "https://www.mlbstatic.com/team-logos/114.svg",
    "COL": "https://www.mlbstatic.com/team-logos/115.svg",
    "CWS": "https://www.mlbstatic.com/team-logos/145.svg",
    "DET": "https://www.mlbstatic.com/team-logos/116.svg",
    "HOU": "https://www.mlbstatic.com/team-logos/117.svg",
    "KC": "https://www.mlbstatic.com/team-logos/118.svg",
    "LAA": "https://www.mlbstatic.com/team-logos/108.svg",
    "LAD": "https://www.mlbstatic.com/team-logos/119.svg",
    "MIA": "https://www.mlbstatic.com/team-logos/146.svg",
    "MIL": "https://www.mlbstatic.com/team-logos/158.svg",
    "MIN": "https://www.mlbstatic.com/team-logos/142.svg",
    "NYM": "https://www.mlbstatic.com/team-logos/121.svg",
    "NYY": "https://www.mlbstatic.com/team-logos/147.svg",
    "OAK": "https://www.mlbstatic.com/team-logos/133.svg",
    "PHI": "https://www.mlbstatic.com/team-logos/143.svg",
    "PIT": "https://www.mlbstatic.com/team-logos/134.svg",
    "SD": "https://www.mlbstatic.com/team-logos/135.svg",
    "SEA": "https://www.mlbstatic.com/team-logos/136.svg",
    "SF": "https://www.mlbstatic.com/team-logos/137.svg",
    "STL": "https://www.mlbstatic.com/team-logos/138.svg",
    "TB": "https://www.mlbstatic.com/team-logos/139.svg",
    "TEX": "https://www.mlbstatic.com/team-logos/140.svg",
    "TOR": "https://www.mlbstatic.com/team-logos/141.svg",
    "WAS": "https://www.mlbstatic.com/team-logos/120.svg"
};

const getColorForDelta = (delta) => {
    if (delta > 0) {
        const greenIntensity = Math.min(255, Math.floor(255 - delta * 20));
        return `rgb(${greenIntensity}, 255, ${greenIntensity})`; // lighter green to dark green
    } else if (delta < 0) {
        const redIntensity = Math.min(255, Math.floor(255 + delta * 20));
        return `rgb(255, ${redIntensity}, ${redIntensity})`; // lighter red to dark red
    } else {
        return ''; // no color for zero
    }
};

const WhosHot = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [handFilter, setHandFilter] = useState('all');
    const [teamFilter, setTeamFilter] = useState('');
    const [sortField, setSortField] = useState('num_100mph_ev');
    const [sortDirection, setSortDirection] = useState('desc');
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [currentDate, setCurrentDate] = useState('');
    const lastElementRef = useRef(null);

    const checkSubscriptionStatus = async (token) => {
        try {
            const response = await axios.get('/api/check-subscription/', {
                params: { token }
            });
            const subscriptions = response.data;
            const currentTime = new Date().toISOString();

            const isSubscribed = subscriptions.some(subscription => 
                subscription.price.product.capper.id === 320906 && 
                subscription.paid_until > currentTime
            );

            return isSubscribed;
        } catch (error) {
            console.error('Error checking subscription status:', error);
            return false;
        }
    };

    const fetchData = async () => {
        setLoading(true);  // Ensure loading state is set to true before fetching data
        try {
            const endpoint = handFilter === 'L' ? 'https://zeusanalytics.org/api/whos-hot-left/' :
                            handFilter === 'R' ? 'https://zeusanalytics.org/api/whos-hot-right/' :
                            'https://zeusanalytics.org/api/whos-hot/';

            const response = await axios.get(endpoint, {
                params: {
                    page: page,
                    team: teamFilter,
                    sort: sortField,
                    direction: sortDirection
                }
            });
            if (response.data.results) {
                setData(prevData => [...prevData, ...response.data.results]);
                setHasMore(response.data.next !== null);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getCurrentDate = () => {
        const now = new Date();
        const utcHour = now.getUTCHours();
        
        if (utcHour < 9) {
            now.setUTCDate(now.getUTCDate() - 1);
        }

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return now.toLocaleDateString('en-US', options);
    };

    useEffect(() => {
        setCurrentDate(getCurrentDate());

        axios.get('/profile/')
            .then(response => {
                const { access_token } = response.data;
                checkSubscriptionStatus(access_token).then(isSubscribed => {
                    setIsAuthenticated(isSubscribed);  // Set the authenticated state
                    if (isSubscribed) {
                        fetchData();
                    } else {
                        window.location.href = 'https://dubclub.win/r/Zeus_Analytics/';
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching profile data:', error);
                window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
            });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
    }, [page, handFilter, teamFilter, sortField, sortDirection]);

    const loadMore = () => {
        if (hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handleHandFilterChange = (hand) => {
        setHandFilter(prevValue => prevValue === hand ? 'all' : hand);
        setPage(1);
        setData([]);
    };

    const handleTeamFilterChange = (event) => {
        setTeamFilter(event.target.value);
        setPage(1);
        setData([]);
    };

    const handleSort = (field) => {
        const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortDirection(direction);
        setPage(1);
        setData([]);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !loading) {
                loadMore();
            }
        }, { threshold: 1.0 });

        if (lastElementRef.current) {
            observer.observe(lastElementRef.current);
        }

        return () => {
            if (lastElementRef.current) {
                observer.unobserve(lastElementRef.current);
            }
        };
    }, [loading, hasMore]);

    if (isAuthenticated === null) {
        return <div>Loading...</div>;  // Display loading until the authentication status is determined
    }

    return (
        <div className="exit-velocity">
            <h1>Who's Hot</h1>
            <h2 style={{ fontSize: '0.8em', marginTop: '-10px', color: '#555' }}>{currentDate}</h2>
            <div className="filters-container">
                <h2>Filters</h2>
                <div className="filters">
                    <div className="filter-group">
                        <div
                            className={`filter-box ${handFilter === 'all' ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('all')}
                        >
                            All Pitchers
                        </div>
                        <div
                            className={`filter-box ${handFilter === 'L' ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('L')}
                        >
                            Left-handed Pitchers
                        </div>
                        <div
                            className={`filter-box ${handFilter === 'R' ? 'active' : ''}`}
                            onClick={() => handleHandFilterChange('R')}
                        >
                            Right-handed Pitchers
                        </div>
                    </div>
                    <div className="filter-group">
                        <div className="team-filter">
                            <label>
                                Team:
                                <select value={teamFilter} onChange={handleTeamFilterChange}>
                                    <option value="">All Teams</option>
                                    {Object.keys(teamNameToLinkDict).map(team => (
                                        <option key={team} value={team}>{team}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table className="modern-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('batter')}>Batter Name {sortField === 'batter' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('batter_team')}>Team {sortField === 'batter_team' && (sortDirection === 'asc' ? '↑' : '↓')}</th>
                            <th onClick={() => handleSort('num_100mph_ev')}>100+ Exit Velo<br />(L7 Days) {sortField === 'num_100mph_ev' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('exit_velocity_avg')}>Exit Velo Avg<br />(L7 Days) {sortField === 'exit_velocity_avg' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('season_exit_velo_avg')}>Exit Velo Avg<br />(Season) {sortField === 'season_exit_velo_avg' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('delta_exit_velo')}>Δ<br />(L7 - Season) {sortField === 'delta_exit_velo' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('hr_count')}>HR's<br />(L7 Days) {sortField === 'hr_count' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('singles')}>Singles<br />(L7 Days) {sortField === 'singles' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('doubles')}>Doubles<br />(L7 Days) {sortField === 'doubles' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                            <th onClick={() => handleSort('triples')}>Triples<br />(L7 Days) {sortField === 'triples' && (sortDirection === 'asc' ? '↓' : '↑')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{row.batter}</td>
                                <td><img src={row.team_logo} alt={row.batter_team} className="team-logo-ev" /> {row.batter_team}</td>
                                <td>{row.num_100mph_ev}</td>
                                <td>{row.exit_velocity_avg?.toFixed(2) || 'N/A'}</td>
                                <td>{row.season_exit_velo_avg?.toFixed(2) || 'N/A'}</td>
                                <td style={{ backgroundColor: getColorForDelta(row.delta_exit_velo) }}>
                                    {row.delta_exit_velo > 0 ? `+${row.delta_exit_velo.toFixed(2)}` : row.delta_exit_velo.toFixed(2)}
                                </td>
                                <td>{row.hr_count}</td>
                                <td>{row.singles}</td>
                                <td>{row.doubles}</td>
                                <td>{row.triples}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loading && <div>Loading...</div>}
                <div ref={lastElementRef} />
            </div>
        </div>
    );
};

export default WhosHot;