// src/utils/teamColors.js

export const teamAbbreviationToColor = {
    'BOS': '#007A33',
    'BKN': '#000000',
    'NYK': '#F58426',
    'PHI': '#006BB6',
    'TOR': '#CE1141',
    'CHI': '#CE1141',
    'CLE': '#860038',
    'DET': '#C8102E',
    'IND': '#FDBB30',
    'MIL': '#00471B',
    'ATL': '#E03A3E',
    'CHA': '#1D1160',
    'MIA': '#98002E',
    'ORL': '#0077C0',
    'WAS': '#E31837',
    'DEN': '#0E2240',
    'MIN': '#0C2340',
    'OKC': '#007AC1',
    'POR': '#E03A3E',
    'UTA': '#002B5C',
    'GSW': '#1D428A',
    'LAC': '#C8102E',
    'LAL': '#FDB927',
    'PHX': '#E56020',
    'SAC': '#5A2D81',
    'DAL': '#00538C',
    'HOU': '#CE1141',
    'MEM': '#5D76A9',
    'NOP': '#0C2340',
    'SAS': '#C4CED4',
    // Add other teams as needed
  };
  